/*------------------------------------
            Account Styles
------------------------------------*/
#cx-account{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:1020;
    display:none;
}
#cx-account.cx-open{
    display:block;
}
.cx-account-out{
    position:relative;
    width:100%;
    height:100%;
}
.cx-filter{
    position:absolute;
    top:0;
    left:0;
    background:rgba(0,0,0,.25);
    width:100%;
    height:100%;
    cursor:pointer;
}
.cx-account-content{
    position: absolute;
    top:0;
    right:-320px;
    background:#ffffff;
    width:320px;
    height:100%;
    transition:all .5s ease;
}
#cx-account.cx-open .cx-account-content{
    right:0;
}
.cx-account-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:60px;
    padding:0 20px;
    border-bottom:1px solid #eeeeee;
}
.cx-account-header button{
    background:transparent;
    border:0;
    padding:0;
}
.cx-account-header h4{
    font-size:16px;
    margin:0;
}
.cx-account-content ul{
    padding:20px;
    padding-top:0;
    height: 91vh;
    overflow-y: scroll;
}
.cx-account-content ul li{
    padding:20px 0;
    border-bottom:1px solid #eeeeee;
}
.cx-account-content ul li a{
    color:#1C0909;
}
.cx-account-content ul li a:hover{
    color:#56B3F4;
}
.cx-account-content ul li a img{
    margin-right:12px;
    position:relative;
    top:-2px;
}

