/*------------------------------------
            FPassword One
------------------------------------*/
#cx-fpass-one{
    height:100%;
    background:#f7f7f7;
}
#cx-fpass-one .container-fluid{
    height:100%;
}
#cx-fpass-one .row{
    height:100%;
}
.cx-fpass-content{
    background:#ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
    padding:50px;
    width:500px;
    text-align: center;
}
.cx-fpass-content h2{
    font-weight: 400;
    font-size: 24px;
}
.cx-fpass-content p{
    font-size: 14px;
    margin-bottom:30px;
}
.cx-fpass-content label{
    font-size:14px;
    margin-bottom:7px;
}
.cx-fpass-btn{
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    background:#56B3F4;
    color:#ffffff;
    height:44px;
    border:0;
    border-radius:10px;
    padding:0 15px;
    transition: all .5s;
    letter-spacing: .7px;
}
.cx-log{
    margin-top:30px;
}
.cx-fpass-btn:hover{
    background:#4997CE;
}

@media(max-width:540px){
    .cx-fpass-content{
        width:auto;
        padding:30px;
    }
    .cx-cinput{
        display: flex;
    }
}



/*------------------------------------
            FPassword Two
------------------------------------*/
#cx-fpass-two{
    height:100%;
    background:#f7f7f7;
}
#cx-fpass-two .container-fluid{
    height:100%;
}
#cx-fpass-two .row{
    height:100%;
}
.cx-cinput input{
    width:44px;
    height:44px;
    display:inline-block;
    margin: 0 10px;
}
.cx-cinput{
    margin-bottom:30px;
    
}



/*------------------------------------
            FPassword Three
------------------------------------*/
#cx-fpass-three{
    height:100%;
    background:#f7f7f7;
}
#cx-fpass-three .container-fluid{
    height:100%;
}
#cx-fpass-three .row{
    height:100%;
}
.cx-cinput input{
    width:44px;
    height:44px;
    display:inline-block;
    margin: 0 10px;
}
.cx-cinput{
    margin-bottom:30px;
}
#cx-fpass-three .cx-fpass-content h2{
    margin-bottom:30px;
}
#cx-fpass-three .cx-fpass-content .cx-form-group{
    text-align: left;
    position: relative;
}
#cx-fpass-three .cx-fpass-content .cx-eye-btn{
    position: absolute;
    top:40px;
    right:15px;
}