/*------------------------------------
            Navbar Styles
------------------------------------*/
header.cx-header {
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.05);
  margin-left: 70px;
  transition: all 0.3s;
}
.cx-navbar {
  height: 60px;
}
.navbar-brand {
  /* Changes By GB */
  display: block;
}
.cx-navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.cx-navbar ul li a {
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.cx-navbar ul li:nth-last-child(1) a {
  padding: 0;
  padding-left: 30px;
  padding-right: 0;
}
.cx-navbar ul li:nth-last-child(1) a img {
  border-radius: 50px;
}

.cx-alert {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  background-color: #ee651e;
  color: #ffffff;
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  border-radius: 50px;
  letter-spacing: 0.5px;
}
header.cx-header.cx-active {
  margin-left: 250px;
}

/*------------------------------------
            Breadcrumb
------------------------------------*/

.cx-breadcrumb ul li {
  display: inline-block;
  color: #1c0909;
}
.cx-breadcrumb ul li img {
}
.cx-breadcrumb ul li.cx-inactive img {
  /* display: none; */
}
.cx-breadcrumb ul li.cx-inactive span {
  padding: 0 15px;
}
.cx-breadcrumb ul li.cx-inactive-p span {
  padding-left: 0;
}
.cx-breadcrumb ul li span {
  padding: 0 15px;
}
.cx-navbar .cx-breadcrumb-list .cx-hrp a {
  padding-left: 0;
}

/*------------------------------------
            Tab Styles
------------------------------------*/
#cx-tab {
  height: 60px;
  transition: all 0.3s;
  border: 0;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
#cx-tab li button {
  border: 0;
  height: 36px;
  line-height: 36px;
  background: #f7f7f7;
  color: #5f5f5f;
  padding: 0 15px;
  border-radius: 5px;
  letter-spacing: 0.7px;
}
#cx-tab li button.active {
  background: #eef8fe;
  color: #56b3f4;
}
#cx-tab li {
  padding-right: 15px;
}

/*------------------------------------
            Action Bar
------------------------------------*/
.cx-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: 1px solid #eeeeee;
  padding: 0 30px;
}
.cx-action-bar button {
  letter-spacing: 1px;
}

.cx-action-bar img {
  margin-right: 10px;
  position: relative;
  top: -2px;
}
.cx-no span {
  color: #ee651e;
}
.cx-no label {
  color: #ee651e;
}

/*------------------------------------
            Media Queries
------------------------------------*/
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .navbar-brand {
    display: block;
  }

  .navbar-nav {
    flex-direction: row;
  }
  #cx-tab {
    margin-left: 0;
    padding: 0 15px;
  }
  header.cx-header {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .cx-breadcrumb {
    display: none;
  }
}
@media (max-width: 576px) {
  main.cx-main {
    height: calc(100vh - 137px) !important;
    margin-left: 70px;
    overflow-x: hidden;
    transition: all 0.3s;
    padding: 20px 20px 0px 20px;
  }
  .cx-navbar .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .iciELI{
    min-width: 223px !important ;
    max-width: 212px !important;
  
  }
  .new-insurance{
    margin-top: 37px !important;
  
  }
  
  .navbar-nav {
    flex-direction: column;
  }

  #cx-tab {
    justify-content: space-between;
    font-size: 14px;
    margin-left: 5px;
  }
  #cx-tab li button {
    padding: 0;
    background: transparent;
    position: relative;
    transition: all 0.5s;
    margin-left: -15px;
  }

  #cx-tab li button.active::before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 30%;
    height: 2px;
    background: #56b3f4;
  }
  #cx-tab li button::before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 0;
    height: 2px;
    background: #56b3f4;
    transition: all 0.5s;
  }
  #cx-tab li:nth-last-child(1) {
    padding-right: 0;
  }
  #cx-tab li button.active {
    padding: 0;
    background: transparent;
  }
  .cx-no span {
    color: #ee651e;
    margin-left: 15px;
  }
  .cx-no label {
    color: #ee651e;
    width: 128px;
    margin-left: -23px;
    margin-top: 11px;
  }
  .cx-action-bar button {
    letter-spacing: -1px;
    width: 178px;
    font-size: 15px;
  }
}
/* .header-img-land{
  width:"100px";
  height:"120px" 
} */

