.stepper-main{
  width: 100%;
  display: flex;
  justify-content: center;
}
.stepper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px;
  padding: 30px;
  max-width: 500px;
  .line {
    width: 2px;
    background-color: #ee651e !important;
  }
  .lead {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
  }
  .step-heading {
    font-weight: 600;
    font-family: Overpass;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #1c0909;
    opacity: 1;
  }
  .step-location {
    font-family: Overpass;
    font-weight: 600;
    font-size: 0.75rem;
    letter-spacing: 0.24px;
    color: #ee651e;
    text-transform: uppercase;
    opacity: 1;
  }
  .step-address {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
  }
  .deliver-man-name {
    font-family: Overpass;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #1c0909;
    opacity: 1;
  }
  .pack-collect {
    font-family: Overpass;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.32px;
    color: #5f5f5f;
    opacity: 1;
    span {
      font-family: Overpass;
      margin-left: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 0.32px;
      color: #1c0909;
      opacity: 1;
    }
  }
  .view-pack {
    margin-left: 1.5rem;
    font-family: Overpass;
    font-weight: normal;
    font-size: 1rem;
    letter-spacing: 0.32px;
    color: #56b3f4;
    opacity: 1;
  }

  .line-img-status {
    margin-bottom: 50px;
    label {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .img-main-status {
      display: flex;
      .inner-img {
        margin-right: 20px;
        position: relative;
        .big-img {
          border-radius: 15px;
          height: 120px;
          width: 120px;
        }
        .cross-icon {
          position: absolute;
          left: 105px;
          top: -8px;
        }
      }
    }
  }
}

.modal-package-status {
  box-shadow: 0px 4px 16px #0000001a;
  .modal-body {
    padding: 0;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-content {
    max-width: 411px;
  }
  .package-modal-heading {
    border-bottom: 1px solid #f0e9e9;
    // margin-bottom: 20px;
    .label {
      padding: 20px 0;
      margin: 0 20px;
      font: normal normal bold 14px/31px Overpass;
      letter-spacing: 0.56px;
      color: #ee651e;
    }
  }
  #inner-package-bottom {
    padding: 20px 0;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    label {
      font: normal normal normal 16px/31px Overpass;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
  }
}
