/*------------------------------------
        Notification Styles
------------------------------------*/
#cxn-notifications{
    padding:30px 0;
}
.cx-ncontent{
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    padding:30px;
    border-radius:15px;
    display:flex;
    position:relative;
}
.cx-ncontent p{
    margin-bottom:5px;
    color:#1C0909;
}
.cx-ncontent img.cxn-not-image{
    margin-right:5px;
    width:32px;
    height:32px;
}
.cx-ncontent span{
    color:#5F5F5F;
    font-size:14px;
}
.cx-ncontent .cx-btn-grp1{
    position:absolute;
    top:30px;
    right:30px;
}
.cx-ncontent button{
    position:relative;
}
.cxn-dropdown{
    position:absolute;
    top:0;
    right:25px;
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.1);
    padding:15px;
    width:150px;
    border-radius:5px;
    display:none;
}
.cxn-dropdown li{
    text-align: left;
}
.cxn-dropdown.cx-active{
    display:block;
}

@media(max-width:576px){
    #cxn-notifications{
        padding:15px 0;
    }
}

