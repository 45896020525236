// .PickupLocationMap-main {
//   height: calc(100vh - 50px) !important;
// }
.location-card {
  padding: 0;

  p {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
    span {
      margin-left: 8px;
      font-weight: 600;
      font-family: Overpass;
      font-size: 0.8rem;
      letter-spacing: 0.28px;
      color: #1c0909;
      opacity: 1;
    }
  }
  h5 {
    font-weight: bold;
    font-family: Overpass;
    font-size: 1.25rem;
    letter-spacing: 0.6px;
    color: #1c0909;
    opacity: 1;
  }
  .location-status {
    font-family: Overpass;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #56b3f4;
    opacity: 1;
  }
  img {
    cursor: pointer;
  }

  .package-details {
    padding: 20px 20px;
    box-shadow: 0px 4px 16px #fc59591a;
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    opacity: 1;
    .package-summary {
      font-family: Overpass;
      font-weight: bold;
      font-size: 0.8rem;
      letter-spacing: 0.56px;
      color: #ee651e;
      opacity: 1;
    }

    .package-name {
      font-family: Overpass;
      font-weight: bold;
      font-size: 0.9rem;
      letter-spacing: 0.48px;
      color: #1c0909;
      opacity: 1;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
  .ongoing-driver-details {
    margin-top: 30px;
  }
  .ongoing-package {
    p {
      font-family: Overpass;
      font-weight: bold;
      font-size: 0.9rem;
      letter-spacing: 0.32px;
      color: #1c0909;
      opacity: 1;
    }
    span {
    }
  }
  .delivery-condition {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.3px;
    color: #1c0909;
    opacity: 1;
  }
  .driver-details {
    padding: 20px 20px;
    box-shadow: 0px 4px 16px #fc59591a;
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    opacity: 1;
    margin-top: 30px;
  }
  .delivery-to-address {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.56px;
    color: #ee651e;
    opacity: 1;
  }
  .driver-img {
    width: 48px;
    height: 48px;
  }
  .driver-name {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.48px;
    color: #1c0909;
    opacity: 1;
  }
  .call-img {
    width: 24px;
    height: 24px;
  }
  .star {
    width: 18px;
    height: 16px;
    margin-right: 10px;
  }
  .rating {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #1c0909;
    opacity: 1;
  }
  .driver-view-details {
    font-family: Overpass;
    margin-left: 55px !important;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #ee651e;
    opacity: 1;
  }
  .address-status {
    display: flex;
    img {
      width: 7px;
      height: 7px;
    }
    .ongoing-delivered {
      font-family: Overpass;
      font-weight: normal;
      font-size: 0.75rem;
      letter-spacing: 0.24px;
      color: #17c238;
      opacity: 1;
    }
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .location-card {
    padding: 20px 0px;
    .location-status {
      margin-right: 60px;
    }
  }
}
