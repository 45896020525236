.video-card iframe {
  border-radius: 10px;
  opacity: 1;
}

.Rating-card {
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px;
  opacity: 1;
  h6 {
    font-weight: 600;
    font-family: Overpass;
    font-size: 0.9rem;
    letter-spacing: 0px;
    color: #1c0909;
    opacity: 1;
  }
  p {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: 0px;
    color: #5f5f5f;
    opacity: 1;
  }
  img {
    padding: 0px 10px;
  }
  .review-btn {
    background: #56b3f4 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: none;
    color: #ffffff;
  }
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}
