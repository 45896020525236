.cxph-outer{
  padding:30px 0;
}
.cxph-content{
  background:#ffffff;
  box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
  padding:30px;
  border-radius:15px;
  display:flex;
  position:relative;
  margin-bottom:30px;
}
.cxph-content img.cxph-thumbnail{
  margin-right:15px;
  width:48px;
  height:48px;
  border-radius:50%;
}
.cxph-content .cxph-direction{
  position:absolute;
  top:30px;
  right:30px;
}
.cxph-content ul li h4{
  font-size:16px;
  font-weight:400;
  margin:0;
}
.cxph-content ul li label{
  font-size:14px;
  margin-right:12px;
}
.cxph-content ul li span{
  font-size:14px;
  font-weight: 600;
}
.cxph-status{
  color:#EE651E;
}
.cxph-content{
  position: relative;
}
.cxph-content .cxph-cost{
  position: absolute;
  top:30px;
  right:30px;
} 
.cxph-routes{
  margin-top:15px;
  position:relative;
}
.cxph-routes img.cxph-route-line{
  position:absolute;
  top: -25px;
  left: 3px;
  bottom:0;
  margin:auto;
}
.cxph-from{
  position: relative;
}
.cxph-from label{
  display: block;
  font-size:12px;
}
.cxph-fdet{
  padding-left:20px;
}
.cxph-from img{
  position:absolute;
  top:5px;
  left:0;
}
.cxph-to{
  position: relative;
  margin-top:30px;
}
.cxph-to label{
  display: block;
  font-size:12px;
}
.cxph-fdet{
  padding-left:25px;
}
.cxph-to img{
  position:absolute;
  top:5px;
  left:0;
}
.cxph-cos{
  display:none;
}
@media(max-width:576px){
  .cxph-content{
      margin-bottom:15px;
  }
  .cxph-cos{
    display:block;
  }
  .cxph-cost{
    display:none;
  }
}