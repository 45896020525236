/*------------------------------------
                Styles
------------------------------------*/
#cxp-details{
    padding:30px 0;
}
.cxp-bdetails-content{
    background-color: #ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
}
.cxp-details-content{
    background-color: #ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
}
.cxp-details{
    padding:30px;
}
.cxp-details-banner{
    background:url('../images/Details/details_bg.png');
    background-size: cover;
    height:150px;
    border-radius:15px 15px 0 0;
}
.cxp-load-image{
    margin-top:-90px;
    position: relative;
}
.cxp-load-image img{
    width:120px;
    height:120px;
    position: relative;
    z-index: 802;
}
.cxp-load-graphic{
    position: absolute;
    width:120px;
    height:120px;
    background-color:#AFDAF8;
    top:-5px;
    left:-5px;
    border-radius:50%;
    z-index: 801;
}
.cxp-load-name{
    position:absolute;
    top:75px;
    left:140px;
}
.cxp-load-name label{
    margin-right:12px;
}
.cxp-details-list{
    padding:30px 0;
    border-bottom:1px solid #e7e7e7;
    display:flex;
    align-items: center;
}
.cxp-details-list li{
    flex-grow:1;
}
.cxp-details-list li label{
    font-size:12px;
}
.cxp-details-list li p{
    color:#1C0909;
    font-weight:600;
    margin:0;
}
.cxp-dcondition{
    padding:30px 0;
    border-bottom:1px solid #e7e7e7;
}
.cxp-dcondition label{
    font-size:12px;
}
.cxp-dcondition p{
    color:#1C0909;
    font-weight:600;
    margin-bottom:0;
}
.cxp-images{
    padding-top:30px;
}
.cxp-images ul li{
    width:72px;
    height:72px;
    display: inline-block;
    margin-right:15px;
}
.cxp-images img{
    display:inline-block;
    width:auto;
    cursor: pointer;
    border-radius:10px;
}
.cxp-images label{
    font-size:12px;
    margin-bottom:15px;
    display:block;
}

.cxp-details-content{
    padding:30px;
    margin-top:30px;
}
.cxp-content-title h4{
    color:#EE651E;
    font-size:16px;
}
.cxp-content-details ul li h4{
    font-size:16px;
    margin:0;
}
.cxp-content-details ul li a{
    font-size:14px;
    text-decoration: underline;
}
.cxp-content-details ul li{
    padding-top:5px;
}



/* Delivery Status Styles */
.cxpc-details-list{
    padding:0;
    border-bottom:0;
}

/* Documents */
.cxp-content-title.cxpc-content-title{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:15px;
}
.cxp-details-content img.cxpc-doc{ 
    width:100%;
    object-fit: cover;
    border-radius:15px;
    height:250px;
}
.cxp-details-content.cxpc-details-content h4{
    font-size:16px;
    font-weight:400;
    margin-bottom:15px;
}
.cxp-details-content.cxpc-details-content .cxpc-doc-no{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top:20px;
}



/*------------------------------------
            Modal Style
------------------------------------*/
.cxp-modal{
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background:rgba(0,0,0,.5);
    display:flex;
    align-items: center;
    justify-content: center;
    opacity:0;
    transition: all .5s;
    pointer-events: none;
    z-index:1010;
}
.cxp-modal.cx-open{
    opacity:1;
    pointer-events:all;
}
.cxp-modal .cxp-gallery-img img{
    transform: scale(.5);
    transition:all .5s;
}
.cxp-modal.cx-open .cxp-gallery-img img{
    transform: scale(1);
}


@media(max-width:991px){
    .cxp-details-list{
        display:block;
    }
    .cxp-details-list li{
        display:inline-block;
        margin-right:50px;
        margin-top:30px;
    }
    .cxp-details-list{
        padding-top:0;
    }
}
@media(max-width:767px){
    .cxp-load-name {
        position: static;
        margin-top: 15px;
    }
}