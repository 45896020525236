/*------------------------------------
            Common Styles
------------------------------------*/
.cxlo-outer {
  padding: 30px 0;
}
.cxlo-content {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.03);
  padding: 30px;
  border-radius: 15px;
  display: flex;
  position: relative;
  margin-bottom: 30px;
}
.cxlo-content img.cxlo-thumbnail {
  margin-right: 15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.cxlo-content .cxlo-direction {
  position: absolute;
  top: 30px;
  right: 30px;
  -webkit-appearance: none;
}
.cxlo-content ul li h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.cxlo-content ul li label {
  font-size: 14px;
  margin-right: 12px;
}
.cxlo-content ul li span {
  font-size: 14px;
  font-weight: 600;
}
.cxlo-status {
  color: #ee651e;
}

.delivery-text{
  font-size: 0.8rem !important;
  color:#5F5F5F;
}
.delivery-number{
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #1C0909;
}
.service-card-load{
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 16px #0000000D;
border-radius: 15px;
padding: 30px;
}
.service-card-load-for-company{
  background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 16px #0000000D;
border-radius: 15px;
padding: 30px;
}
.waiting-accept{
  font-size: 0.8rem !important;
color:#EE651E;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  /* min-width: 10rem; */
  /* padding: 0.5rem 0; */
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  /* border-radius: 0.25rem; */
  width: 110px;
  padding: 20px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
  box-shadow: 0px 4px 16px #00000019;
border-radius: 10px;

}
.pick-up-driver{
  color:#56B3F4;
  font-size: 0.8rem;
}

.arrow-btn-company{
  border: none;
  border-radius: 50%;
  background-color: #56B3F4;
  height: 30px;
  width: 30px;
}
.reject-btn{
  border: 1px solid #56B3F4;
  color: #56B3F4;
  background: none;
  width: 90px;
height: 36px;
border-radius: 18px;
}
.accept-btn{
  background-color: #56B3F4;
  color: white;
  border: none;
  width: 90px;
height: 36px;
border-radius: 18px;
margin-left: 15px;
}

@media (max-width: 576px) {
  .cxlo-content {
    margin-bottom: 15px;
  }
}

.load-details-delevery-details label {
  margin-right: 10px;
  font-size: 12px;
}
.load-details-delevery-details .black-value {
  font: normal normal 600 14px/31px Overpass;
  letter-spacing: 0.28px;
  color: #1c0909;
}
.load-details-delevery-details .orange-value {
  font: normal normal 600 14px/31px Overpass;
  letter-spacing: 0.28px;
  color: #ff8900;
}

@media all and (max-width:766px){
  /* .service-card-load{
    margin-top: 25px !important;
  } */
}

#service-request-menu li a{
  font-size: 16px;
  letter-spacing: 0.64px;
  color: #1C0909;
}
#service-request-menu{
  min-width: 120px;
}

.cxlo-thumbnail{
    margin-right: 15px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
}