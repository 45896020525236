/*------------------------------------
            General Styles
------------------------------------*/
#cx-sidebar{
    position:fixed;
    top:0;
    left:0;
    height:100%;
    background:url('../images/Sidebar/sidebar_bg.svg');
    padding-top:0;
    width:70px;
    transition: all .3s;
    white-space: nowrap;
    z-index:1010;
}
.cx-container{
    width:100%;
}
.cx-list li{
    font-size: 16px;
    position: relative;
    border-bottom:1px solid #D95B19;
}
.cx-list li a{
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:60px;
    transition: all .5s ease;
    background:transparent;
    position: relative;
    color:#ffffff;
}
.cx-list li a span{
    display:none;
}
.cx-list.cx-active li a{
    padding-left:20px;
}
.cx-list.cx-active li a img{
    padding-right:10px;
}
.cx-list.cx-active li a span{
    display:block;
    position: relative;
    top:2px;
}
.cx-list li.cx-nav-active a{
    background:rgba(255,255,255,.15);
    color:#ffffff !important;
}
.cx-sidebar-brand{
    height:61px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #D95B19;
}
.cx-sidebar-brand .cx-brand-icon-text{
    display:none;
}
.cx-sidebar-brand.cx-active{
    justify-content: start;
    padding-left:20px;
}
.cx-sidebar-brand.cx-active .cx-brand-icon-text{
    display:block;
}
.cx-list li .cx-tooltip{
    padding:0 10px;
    position: absolute;
    top:0;
    bottom:0;
    margin:auto;
    right:-150px;
    width:140px;
    height:40px;
    line-height: 40px;
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.05);
    border-radius:5px;
    opacity:0;
    z-index:1011;
    pointer-events: none;
}
.cx-tooltip::after{
    content:'';
    position:absolute;
    top:12px;
    left:-7px;
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    border-right:7px solid #56B3F4;
}
.cx-list li:hover .cx-tooltip{
    opacity:1;
    z-index:1011;
}
.cx-list li .cx-tooltip:hover{
    opacity:0;
}



/*------------------------------------
                Toggler
------------------------------------*/
#cx-sidebar-toggler{
    height:60px;
    border-bottom:1px solid #D95B19;
    line-height:60px;
    display:flex;
    align-items: center;
    justify-content: center;
}
#cx-sidebar-toggler button{
    height:60px;
    width:40px;
    background:transparent;
    border:0;
}
#cx-sidebar-toggler button svg{
    fill:#ffffff !important;
}
#cx-sidebar-toggler button:hover svg{
    fill:#ffffff !important;
}
#cx-sidebar-toggler.cx-active{
    justify-content: end;
    padding-right:15px;
}
#cx-sidebar-toggler.cx-active button{
    width:100%;
    transform: rotate(180deg);
}






/*------------------------------------
                Account
------------------------------------*/
.cx-account img{
    width:36px;
    height:36px;
}


/*------------------------------------
               Active Styles
------------------------------------*/
#cx-sidebar.cx-active{
    width:250px;
    background:url('../images/Sidebar/sidebar_bg.svg');
}
.cx-list.cx-active li a{
    text-align: left;
}
.cx-list.cx-active li a .cx-list-c{
    right:unset;
    left:12px;
}
.cx-sidebar-brand.cx-active a .cx-brand-logo {
   width:100px;
   opacity:1;
}
.cx-list.cx-active li a span{
    width:100%;
    opacity:1;
}
.cx-sidebar-brand.cx-active {
    text-align: left;   
}
.cx-sidebar-brand.cx-active a .cx-brand-icon {
    margin-right:15px;
}
.cx-list.cx-active li .cx-tooltip{
    display:none;
}





/*------------------------------------
            Media queries
------------------------------------*/

@media(max-width:991px){
    #cx-sidebar{
        position:fixed;
        top:unset;
        left:0;
        bottom:0;
        height:48px;
        background:#EE651E;
        padding-top:0;
        width:100%;
        transition: all .3s;
        white-space: nowrap;
        z-index:1010;
    }
    .cx-container{
        height:100%;
    }
    .cx-sidebar-brand{
        display:none;
    }
    #cx-sidebar-toggler{
        display:none;
    }
    .cx-list{
        display:flex;
        align-items: center;
        height:100%;
    }
    .cx-list li{
        display:inline-block;
        flex-grow:1;
    }
    .cx-list li a {
        height:48px;
    }
    #cx-sidebar.cx-active{
        width:0;
        background:url('../images/Sidebar/sidebar_bg.svg');
    }
}

.sidebar-logo-name-top{
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
    color: #ffffff;
}
