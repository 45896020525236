.load-details-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px 15px 0px 0px;
  opacity: 1;
  padding: 28px 29px;

  h6 {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #ee651e;
  }
  span {
    font-weight: bold;
    font-family: Overpass;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #56b3f4;
    opacity: 1;
  }
  label {
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.8rem;
    letter-spacing: 0.24px;
    color: #5f5f5f;
    opacity: 1;
  }
  p {
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #1c0909;
    opacity: 1;
  }
  .address {
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.85rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
  }
  .detail-btn {
    cursor: pointer;
  }
  .form-control {
    border: 1px solid #dddddd;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
  }
  .form-select:focus {
    box-shadow: none;
  }
}
.load-details-card-1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px 0px 0px 0px;
  opacity: 1;
  padding: 28px 29px;
  h6 {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.32px;
    color: #ee651e;
  }
  .bookingDetails {
    padding-bottom: 30px;
    p {
      font-family: Overpass;
      font-weight: normal;
      font-size: 0.9rem;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
    span {
      color: #ee651e;
    }
    img {
      cursor: pointer;
    }
    .offer-typo {
      font-weight: normal;
      font-family: Overpass;
      font-size: 0.9rem;
      letter-spacing: 0.32px;
      color: #1c0909;
      opacity: 1;
    }
    .offer-typo-2 {
      font-weight: normal;
      font-family: Overpass;
      font-size: 0.8rem;
      letter-spacing: 0.28px;
      color: #5f5f5f;
      opacity: 1;
    }
    h5 {
      font-weight: bold;
      font-size: 1rem;
      font-family: Overpass;
      letter-spacing: 0.32px;
      color: #56b3f4;
      opacity: 1;
    }
    .delivery-card {
      padding: 15px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #f0e9e9;
      border-radius: 15px;
      opacity: 1;
      h6 {
        font-family: Overpass;
        font-weight: normal;
        font-size: 0.9rem;
        letter-spacing: 0.32px;
        color: #1c0909;
        opacity: 1;
      }
      .deliver-address {
        font-family: Overpass;
        font-weight: normal;
        font-size: 0.8rem;
        letter-spacing: 0.28px;
        color: #5f5f5f;
        opacity: 1;
      }
      .delivery-number {
        text-decoration: underline;
        font-weight: normal;
        font-family: Overpass;
        font-size: 0.8rem;
        letter-spacing: 0.28px;
        color: #0a7ff8;
        opacity: 1;
      }
      .form-select {
        background: #f7f7f7 url("../images/next_arrow_right.svg") 90% 50%
          no-repeat padding-box;
      }
    }
    .cost_typo {
      font-weight: normal;
      font-family: Overpass;
      margin-top: 12px !important;
      font-size: 0.9rem;
      letter-spacing: 0.32px;
      color: #5f5f5f;
      opacity: 1;
    }
    .delivery_price {
      font-weight: 600;
      font-family: Overpass;

      font-size: 0.9rem;
      letter-spacing: 0.32px;
      color: #1c0909;
      opacity: 1;
    }
    label {
      font-family: Overpass;
      font-weight: normal;
      font-size: 0.75rem;
      letter-spacing: 0.24px;
      color: #5f5f5f;
      opacity: 1;
    }
    .form-control {
      background-image: none;
      border: 1px solid #dddddd;
      border-radius: 10px;
      opacity: 1;
      margin-bottom: 15px;
    }
  }
}

.load-details-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 0px 0px 15px 15px;
  opacity: 1;
  padding: 11px 30px;
  span {
    font-weight: bold;
    font-family: Overpass;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #56b3f4;
    opacity: 1;
  }
  .detail-btn {
    cursor: pointer;
  }
}

.custom-width {
  margin: auto !important;
  max-width: 800px !important;
}

.package-details {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #fc59591a;
  border: 1px solid #f0e9e9;
  border-radius: 15px;
  opacity: 1;
  .package-summary {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.56px;
    color: #ee651e;
    opacity: 1;
  }

  p {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.85rem;
    letter-spacing: 0.32px;
    color: #1c0909;
    opacity: 1;
  }
  img {
    cursor: pointer;
  }
  .ongoing-delivered {
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.75rem;
    letter-spacing: 0.24px;
    color: #17c238;
    opacity: 1;
  }
  .ongoing-packages {
    p {
      font-weight: bold;
      font-family: Overpass;
      font-size: 0.75rem;
      letter-spacing: 0.48px;
      color: #5f5f5f;
      opacity: 1;
    }
    .package-item {
      padding: 12px 12px;
      box-shadow: 0px 4px 16px #fc59591a;
      border: 1px solid #f0e9e9;
      border-radius: 10px;
      opacity: 1;
    }
  }
}
.driver-details {
  padding: 20px 20px;
  box-shadow: 0px 4px 16px #fc59591a;
  border: 1px solid #f0e9e9;
  border-radius: 15px;
  opacity: 1;
  margin-top: 30px;
  .driver-img {
    width: 48px;
    height: 48px;
  }
  .driver-name {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.48px;
    color: #1c0909;
    opacity: 1;
  }
  .call-img {
    width: 24px;
    height: 24px;
  }
  .star {
    width: 18px;
    height: 16px;
    margin-right: 10px;
  }
  .rating {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #1c0909;
    opacity: 1;
  }
  .driver-view-details {
    font-family: Overpass;
    margin-left: 55px !important;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #ee651e;
    opacity: 1;
  }
}

.upload-subtitle {
  label {
    padding: 7px 0px;
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.75rem;
    letter-spacing: 0.28px;
    opacity: 1;
    color: #5f5f5f;
  }
  span {
    padding: 7px 0px;
    color: #ff0000;
  }
}

.vehicle_img {
  z-index: 0;
}

.close_item {
  position: absolute;
  left: 45px;
  z-index: 5;
}

.acco-check .alert-check input {
  display: none;
}
.acco-check .label-text:before {
  content: url("../images/ic_checkbox_selected.svg");
}
.acco-check .alert-check input:checked + .label-text::before {
  content: url("../images/ic_checkbox_selected\ \(1\).svg");
}

.acco-check .check-label {
  font-family: Open Sans;
  font-size: 0.8rem;
  letter-spacing: 0.42px;
  color: #ffffff;
  opacity: 1;
}
.canvas-btn {
  position: absolute;
  left: -30px;
  top: calc(50% - 60px);
  transform: translateY(calc(-50% + 90px));
  border: none;
  width: 30px;
  height: 82px;
  border-radius: 20px 0 0 20px;

  background: #fdfeff 0% 0% no-repeat padding-box;
  box-shadow: -4px 4px 16px #0000000d;
  opacity: 1;
}
.canvas-btn-right {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
    border: none;
  width: 30px;
  height: 82px;
  border-radius: 20px 0 0 20px;
  background: #fdfeff 0% 0% no-repeat padding-box;
  box-shadow: -4px 4px 16px #0000000d;
  opacity: 1;
}

