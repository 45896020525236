.left_block {
  background-color: #ee651e;
  height: 100vh;
  overflow: hidden;
  padding: 50px;
  position: relative;
}

.left_block h2 {
  color: #fff;
  font-size: 24px;
  line-height: 29px;
}

.left_block .lf_img img {
  position: absolute;
  top: 0;
  left: 0;
}

.right_block p.step_num {
  color: #56b3f4;
  font-size: 16px;
  letter-spacing: 0.38px;
  font-weight: 500;
}

.wizard_mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.right_block.cx-reg-content {
  box-shadow: none;
  width: auto;
  position: relative;
  border-radius: 0px;
  height: 100%;
  overflow-y: scroll;
}

/* .right_block.cx-reg-content {
      box-shadow: none;
      width: 100%;
      position: relative;
      border-radius: 0px;
      flex-direction: column;
      justify-content: end;
      height: 100%;
  } */

a.orange_btn {
  background-color: #ee651e;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.43px;
  font-weight: 500;
  padding: 12px 50px;
}

a.blue_link {
  color: #56b3f4;
}

a.blue_btn,
button.blue_btn {
  background: #56b3f4 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  padding: 7px 23px;
  letter-spacing: 0.32px;
  color: #ffffff;
  border: 1px solid #56b3f4;
  -webkit-appearance: none;
}

a.blue_btn_outline {
  background: #fff;
  border-radius: 10px;
  color: #56b3f4;
  padding: 7px 23px;
  letter-spacing: 0.32px;
  border: 1px solid #56b3f4;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.blue_btn_outline:hover {
  background-color: #56b3f4;
  color: #fff;
  border: 1px solid #56b3f4;
}
.rfbtn_block button.orange_btn {
  background-color: #ee651e;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.43px;
  font-weight: 500;
  padding: 12px 50px;
  border: none;
}
.rfbtn_block button.orange_btn:hover {
  color: #56b3f4;
}

/* .rfbtn_block {
      position: absolute;
      bottom: 0;
  } */

/* Step Process Three */

.step {
  position: relative;
  min-height: 4em;
}

.step + .step {
  margin-top: 1.5em;
}

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

.step.step-active {
  color: #4285f4;
}

.step.step-active .circle {
  background-color: #fff;
  /* box-shadow: 0 0 0 6px #ffffff2e; */
  color: #fff;
  width: 20px;
  height: 20px;
}

.step.step-complete .circle:before {
  content: "";
  position: absolute;
  background-image: url(../images/step_check.svg);
  width: 100%;
  height: 100%;
  top: 4px;
  background-repeat: no-repeat;
  left: 4px;
}

.step.step-active .title,
.step.step-complete .title {
  color: #fff;
}

.step.step-complete .circle {
  background-color: #fff;
  /* box-shadow: 0 0 0 6px #ffffff2e; */
  color: #f9c9b0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: relative;
}

.step.step-complete .circle::after,
.step.step-active .circle::after {
  left: 8px;
  top: -20px;
}

.step .circle {
  background: #f9c9b0;
  position: relative;
  width: 16px;
  height: 16px;
  line-height: 1.5em;
  border-radius: 100%;
  color: #f9c9b0;
  text-align: center;
  z-index: 11;
  font-size: 0;
}

.step .circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: -24px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 40px;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: #f9c9b0;
}

.step:last-child .circle:after {
  display: none;
}

.step .title {
  line-height: 1.5em;
  font-weight: 500;
  color: #f9c9b0;
  letter-spacing: 0.54px;
}

/* Step Process Three */

.register_right label {
  font-size: 14px;
  text-align: left;
  /* margin-bottom: 7px; */
  color: #5f5f5f;
  height: -webkit-fill-available;
}

.drop_block {
  background-color: #f0f4f8;
  border-radius: 7px;
  border: 1px dashed #d1e1f0;
  display: table;
  overflow: hidden;
  height: 150px;
  width: 100%;
}

.vertical_m {
  display: table-cell;
  vertical-align: middle;
}

.drop_block p {
  font-size: 12px;
  letter-spacing: 0.29px;
  color: #56b3f4;
}

.uploaded_file {
  border: 1px solid #f0f6fc;
  border-radius: 8px;
  padding: 5px 11px 2px;
  margin-bottom: 15px;
}

.right_block .drop_input .form-control {
  height: 36px;
  border: 1px solid #f0f6fc;
  border-radius: 8px;
  font-size: 14px;
  color: #5f5f5f;
}

.itemside {
  position: relative;
  display: flex;
  width: 100%;
}

.itemside .aside {
  position: relative;
  flex-shrink: 0;
}

.itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

.drivers_list .itemside .title {
  display: block;
  margin-bottom: 5px;
  color: #1c0909;
  letter-spacing: 0.32px;
  font-size: 16px;
}

.drivers_list p.smttitle {
  font-size: 14px;
  color: #5f5f5f;
  letter-spacing: 0.28px;
  font-weight: 400;
  margin-bottom: 5px;
}

.itemside p {
  margin: 0;
}

.drop_dot.dropstart .dropdown-toggle::before {
  display: none;
}

.drop_dot a.btn.dropdown-toggle {
  padding: 0px;
}

.drop_dot a.btn.dropdown-toggle:focus {
  box-shadow: none;
}

.drop_dot .dropdown-menu.show {
  display: block;
  box-shadow: 0px 3px 10px #0091b433;
  border-radius: 5px;
  border: 0;
}

/* 
  .drop_dot .dropdown-menu.show li {
      padding: 0px 0px;
  } */

.drop_dot .dropdown-menu.show .dropdown-item {
  font-size: 14px;
}

.drivers_list {
  border-top: 1px solid #eeeeee;
  padding: 28px 0px;
}

.cx-reg-content h2 {
  letter-spacing: 0.58px;
}

.drivers_list p.smttitle span.catvehcile {
  color: #1c0909;
  font-weight: 500;
}

.drop_block.vehicle_drop {
  background-color: #fff;
  border: 1px dashed #dddddd;
  height: 242px;
}

.drop_block.vehicle_drop p {
  font-size: 14px;
  letter-spacing: 0.56px;
  color: #bcb7b7;
}

.drop_block.vehicle_drop a {
  font-size: 14px;
  letter-spacing: 0.56px;
  color: #56b3f4;
  text-decoration: underline;
}

.uploaded_items .item_list {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  margin-top: 28px;
}

.cx-reg-content select.form-select.form-control {
  line-height: 28px;
}

/* .uploaded_items .item_list img.vehicle_img {
      position: relative;
  } */

.uploaded_items .item_list a.close_item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
  left: 38px;
  z-index: 11;
}

.card.card_style_2 {
  background-color: #eff7fd;
  border: 7px solid #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 16px #00000008;
}

.card.card_style_2 p.smtitle {
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #1c0909;
  margin-bottom: 5px;
}

.card.card_style_2 h3 {
  font-size: 22px;
  letter-spacing: 0.3px;
  color: #1c0909;
  font-weight: 500;
}

.card.card_style_2 .card-body {
  padding: 22px 36px;
}

.total_loads .load_count {
  display: inline-block;
  margin-right: 58px;
}

.card.card_style_2 p.title {
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-bottom: 5px;
  color: #5f5f5f;
}

.card.card_style_2 p.count {
  font-size: 22px;
  letter-spacing: 0.3px;
  margin: 0;
  color: #1c0909;
  font-weight: 600;
}

.card.card-style {
  background-color: #fff;
  border: 0;
  box-shadow: 0px 4px 16px #00000008;
  border-radius: 15px;
  height: auto;

}

.card.card-style .card-body {
  padding: 24px;
}

.single_count p {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.36px;
}

.single_count h4.blue {
  color: #4867f8;
}

.single_count h4.yellow {
  color: #f1a933;
}

.single_count h4.green {
  color: #56d0a8;
}

.single_count h4.sea {
  color: #5ac4f1;
}

.card.card-style .card_title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 9px;
}

.card.card-style .card_title h4 {
  letter-spacing: 0.32px;
  color: #1c0909;
  font-size: 18px;
  font-weight: 500;
}

.user_block p {
  letter-spacing: 0.32px;
  color: #1c0909;
}

.cxl-driver-details .nav-pills .nav-link.active {
  background-color: transparent;
  letter-spacing: 0.48px;
  color: #56b3f4;
  position: relative;
}

.cxl-driver-details .nav-pills .nav-link {
  background-color: transparent;
  letter-spacing: 0.48px;
  color: #1c0909;
  padding: 0px 30px 0px 0px;
}

.cxl-driver-details .cxl-lpdetails h5 {
  letter-spacing: 0.48px;
  color: #1c0909;
  font-size: 16px;
  font-weight: 500;
}

.cxl-rating img {
  display: inline-block;
  margin-right: 4px;
}

.cxl-driver-details .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  background-color: #56b3f4;
  width: 50px;
  height: 3px;
  bottom: -15px;
  left: 0;
}

.cxp-delivery-block ul li {
  display: inline-block;
  width: 33%;
}

.cxp-delivery-block ul li h5 {
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #5f5f5f;
  font-weight: 500;
}

.cxp-delivery-block ul li p {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #1c0909;
  margin: 0;
}

.cx-live-track-driver {
  position: absolute;
  top: 19px;
  left: 19px;
}

.ViewDrivers .offcanvas-title {
  letter-spacing: 0.48px;
  color: #1c0909;
  font-size: 18px;
  font-weight: 500;
}

.ViewDrivers .offcanvas-header {
  border-bottom: 1px solid #eeeeee;
}

.ViewDrivers .cxl-lpdetails h5 {
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #1c0909;
  font-weight: 500;
}

.ViewDrivers .cxl-lpdetails {
  border-bottom: 1px solid #eeeeee;
}

div#ViewDrivers {
  border: 0;
  box-shadow: 0px 4px 16px #0000000d;
}

.ViewDrivers .cxl-rating {
  margin-bottom: 5px;
}

.drivers_toggle {
  position: absolute;
  bottom: 50px;
  right: 30px;
}

.drivers_toggle.active {
  position: absolute;
  bottom: 50px;
  right: 60vh;
}

.drivers_toggle a.driver_panel {
  background-color: #fff;
  width: 50px;
  height: 50px;
  padding: 21px;
  border-radius: 50px;
  line-height: 1px;
}

.drivers_toggle a.driver_panel.active {
  background-color: #ee651e;
}

.service_loads {
  padding: 22px 24px;
  background-color: #fff;
  box-shadow: 0px 4px 16px #00000008;
}

.service_loads .nav-pills .nav-link {
  border: 0;
  background: #f7f7f7;
  border-radius: 7px;
  letter-spacing: 0.28px;
  color: #5f5f5f;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}

.service_loads .nav-pills .nav-link.active {
  background: #eef8fe;
  color: #56b3f4;
}

.service_load_block {
  padding: 22px 24px;
}

.service_loads h4.title {
  font-size: 16px;
  letter-spacing: 0.64px;
  color: #ee651e;
  position: relative;
  top: 9px;
}

.service_loads a.blue_btn {
  font-size: 14px;
  font-weight: 600;
}

.cx-reg-content.service-reg-content {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%);
  border-radius: 15px;
  padding: 15px 30px 30px;
  width: 100%;
  margin: 0;
}

.cx-reg-content.service-reg-content .cx-form-group {
  position: relative;
  padding-left: 0px;
}

.service-reg-content .drop_input .form-control {
  height: 36px;
  border: 1px solid #f0f6fc;
  border-radius: 8px;
  font-size: 14px;
  color: #5f5f5f;
}

p.regular_black {
  margin: 0;
  letter-spacing: 0.28px;
  color: #1c0909;
}

.service_load_num ul li {
  border-radius: 10px;
  background: #fc59590d;
  display: inline-block;
  width: 28%;
  padding: 12px;
  margin-right: 12px;
}

.service_load_num ul li h5 {
  font-size: 13px;
  letter-spacing: 0.36px;
  color: #5f5f5f;
  font-weight: 400;
}

.service_load_num ul li p {
  font-size: 20px;
  letter-spacing: 0.6px;
  color: #fc5959;
  font-weight: 600;
  margin: 0;
}

hr.lightblack {
  color: #eeeeee;
  opacity: 1;
}

h3.smorangetitle {
  font-size: 16px;
  letter-spacing: 0.64px;
  color: #ee651e;
}

.service_social ul li {
  display: inline-block;
  width: 32%;
}

.service_social.assigned_vehicle ul li {
  width: 16%;
  /* margin-right: 46px; */
}

.service_social.assigned_vehicle ul li:last-child {
  width: auto;
  margin-right: 0;
}

.card.document_card {
  box-shadow: 0px 4px 16px #fc59591a;
  border: 1px solid #f0e9e9;
  border-radius: 15px;
  width: 35%;
  margin-right: 16px;
}

.registercard_list {
  position: relative;
}
@media (max-width: 375px) {
  .registercard_list {
    flex-direction: column;
  }
}

.registercard_list .slide_right {
  position: relative;
}

.registercard_list .slide_right {
  position: absolute;
  right: -28px;
  top: 28%;
}

.contact_social {
  padding: 60px 100px 0px;
  /* text-align: center; */
}

.contact_social a {
  font-weight: 600;
  letter-spacing: 0.21px;
  color: #1c0909;
  font-size: 14px;
}

.page_block p {
  letter-spacing: 0.28px;
  color: #1c0909;
  font-size: 14px;
}

p.payment_current {
  letter-spacing: 0.6px;
  color: #1c0909;
  font-size: 20px;
  font-weight: 600;
}

.frompath {
  position: relative;
  margin-top: 15px;
}

.frompath p.small,
.topath p.small {
  font-size: 12px;
}

.frompath:before {
  position: absolute;
  content: "";
  background-image: url(../images/from_circle.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  left: -18px;
  top: 2px;
}

.frompath:after {
  position: absolute;
  content: "";
  background-image: url(../images/from_path.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 1px;
  height: 74px;
  left: -14px;
  top: 14px;
}

.topath {
  margin-top: 30px;
  position: relative;
}

.topath:before {
  position: absolute;
  content: "";
  background-image: url(../images/from_location.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 10px;
  height: 13px;
  left: -18px;
  top: 2px;
}

.driver_service_block.add_vehicle_service {
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px;
  background-color: #fff;
  padding: 28px;
}

.driver_service_block.add_vehicle_service .cx-form-group {
  padding-left: 0;
}

.add_vehicle_form.cx-reg-content {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 50px;
  width: 100%;
}

.add_vehicle_form .drop_input .form-control {
  height: 36px;
  border: 1px solid #f0f6fc;
  border-radius: 8px;
  font-size: 14px;
  color: #5f5f5f;
}

.service_social.assigned_vehicle_block ul li {
  width: 18%;
}

.service_social.assigned_vehicle_block ul li p {
  letter-spacing: 0.28px;
  color: #5f5f5f;
}

.service_social.assigned_vehicle_block ul li p.regular_black {
  letter-spacing: 0.32px;
  color: #1c0909;
  font-size: 16px;
  font-weight: 600;
}

.threedot_drop a.btn.dropdown-toggle {
  padding: 0;
}

.threedot_drop a.btn.dropdown-toggle:focus {
  box-shadow: none;
}

.threedot_drop .dropdown-toggle::after {
  display: none;
}

.btn-group.threedot_drop ul.dropdown-menu {
  border: 0;
  box-shadow: 0px 4px 16px #00000029;
  border-radius: 9px;
  text-align: right;
}

.earn_stats p.small {
  margin: 0px 0px 5px;
  font-size: 12px;
  letter-spacing: 0.36px;
  color: #5f5f5f;
}

.earn_stats p.title {
  margin: 0;
  letter-spacing: 0.6px;
  color: #1c0909;
  font-size: 20px;
  font-weight: 600;
}

.earn_stats {
  padding-bottom: 22px;
}

.top_drivers h4 {
  letter-spacing: 0.32px;
  color: #1c0909;
  font-size: 16px;
  font-weight: 500;
}

.top_drivers thead tr th {
  letter-spacing: 0.28px;
  color: #5f5f5f;
  font-size: 14px;
  font-weight: 500;
}
.iframeOtrWrapper > iframe {
  display: flex;
  height: 120vh;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .card.document_card {
    margin-top: 10px;
  }
  .service_load_num ul li {
    display: block;
  }

  .cx-login-content {
    padding: 22px;
  }
  /* .cxl-driver-details .nav-pills .nav-link.active:after {
          bottom: -29px;
      } */
  .cxl-driver-details .nav-pills .nav-link {
    padding: 0px 9px 0px 0px;
  }
  .cxl-details {
    position: absolute;
    bottom: 30px;
    left: auto;
    right: auto;
    margin: 0px 13px;
  }
  .cxl-status {
    position: relative;
    top: 0;
    left: auto;
    right: auto;
    margin: auto;
    padding: 0;
    height: auto;
  }
  .cxl-driver-details {
    margin-top: 15px;
  }
  .cxl-lbtns button {
    margin-left: 5px;
  }
  .cxl-routes div {
    margin-right: 16px;
  }
  .cxl-route-info {
    padding: 0;
  }
  .left_block,
  .right_block.cx-reg-content {
    height: auto;
  }
  .drivers_toggle.active {
    bottom: 82px;
    right: 15px;
  }
  .cxp-delivery-block ul li {
    width: auto;
    margin: 0px 22px 16px 0px;
  }
  .service_loads .nav-pills .nav-link {
    margin-bottom: 10px;
  }
  .service_load_num ul li {
    margin: 18px 12px 0px 0px;
    width: auto;
  }
  .service_social ul li {
    width: auto;
    margin-bottom: 15px;
    display: block;
  }
  .service_social.assigned_vehicle ul li {
    width: auto;
    margin: 0px 20px 20px 0px;
  }
  .card.document_card {
    width: 100%;
    display: inline-table;
  }

  .add_vehicle_form.cx-reg-content {
    padding: 0px;
    width: 100%;
    margin: 0;
  }
  .service_social.assigned_vehicle_block ul li {
    width: auto;
    margin-right: 22px;
  }
  .contact_block {
    padding-top: 0px !important;
  }
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* .cx-login-content {
          padding: 22px;
      } */
  .cx-login-content {
    width: 372px;
  }
  .left_block,
  .right_block.cx-reg-content {
    height: auto;
  }
  .drivers_toggle.active {
    bottom: 89px;
    right: 35vh;
  }
  .cxp-delivery-block ul li {
    width: 32%;
  }
}

@media only screen and (max-device-width: 791px) {
  .service_social.assigned_vehicle ul li {
    display: block;
  }
}


.iciELI{
  border: dashed 2px #D1E1F0 !important;
}