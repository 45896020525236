/*------------------------------------
                Styles
------------------------------------*/
#cxcu-contact{
    height:100%;
}
#cxcu-contact .container{
    height:100%;
}
#cxcu-contact .row{
    height:100%;
}
.cxcu-contact-outer{
    width:800px;
}
.cxcu-contact-in{
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    padding:30px;
    border-radius:15px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:360px;
}
.cxcu-contact-in img.cxcu-logo{
    margin-bottom:50px;
}
.cxcu-contact-in > ul > li > img{
    margin-right:12px;
    padding:10px 0;
}
.cxcu-contact-in > ul > li > span{
    position: relative;
    top:2px;
}
.cxcu-contact-in .cx-form-group{
    width: 100%;
}
.cxcu-contact-in .cx-form-group textarea{
    height:150px;
}
.cx-btn-grp{
    width:100%;
}
.cxcu-contact-in h4{
    font-size:18px;
}
.cx-btn-grp button{
    width:100%;
    height:38px;
    padding:0;
    line-height:38px;
    border:0;
    border-radius:5px;
}

@media(max-width:576px){
    .cxcu-contact-in{
        margin-bottom:15px;
    }
    #cxcu-contact .row{
        height:auto;
    }
    #cxcu-contact{
        height:100%;
        padding:15px 0;
    }
}