.live-details-card {
  padding: 30px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px;
  opacity: 1;
  .details-card {
    padding: 30px 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    opacity: 1;
    h6 {
      margin: 0;
      font-family: Overpass;
      font-weight: bold;
      font-size: 0.8rem;
      letter-spacing: 0.56px;
      color: #ee651e;
      opacity: 1;
    }
    p {
      margin: 0px;
      font-family: Overpass;
      font-weight: 500;
      font-size: 0.8rem;
      letter-spacing: 0.28px;
      color: #5f5f5f;
      opacity: 1;
    }
    .instruction {
      font-family: Overpass;
      font-weight: bold;
      font-size: 0.8rem;
      letter-spacing: 0.56px;
      color: #1c0909;
      opacity: 1;
    }
  }
  .delivery-to-address {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.56px;
    color: #ee651e;
    opacity: 1;
  }
  .driver-img {
    width: 48px;
    height: 48px;
  }
  .driver-name {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.48px;
    color: #1c0909;
    opacity: 1;
  }
  .call-img {
    width: 24px;
    height: 24px;
  }
  .star {
    width: 18px;
    height: 16px;
    margin-right: 10px;
  }
  .rating {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #1c0909;
    opacity: 1;
  }
  .driver-view-details {
    font-family: Overpass;
    margin-left: 55px !important;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #ee651e;
    opacity: 1;
  }
  .packages {
    margin-top: 30px;
    h6 {
      font-family: Overpass;
      font-weight: bold;
      font-size: 14px/31px;
      letter-spacing: 0.56px;
      color: #ee651e;
      opacity: 1;
    }
  }
  .package-img {
    img {
      width: 72px;
      height: 72px;
      border-radius: 10px;
      opacity: 1;
    }
  }
}
