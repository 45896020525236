/*------------------------------------
            Map Styles
------------------------------------*/
#cxs-live {
  height: calc(100vh - 60px);
  position: relative;
}
#cxl-map {
  height: calc(100vh - 60px);
}

/*------------------------------------
            Status Style
------------------------------------*/
.cxl-status {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 20px;
  height: 140px;
  display: none;
}
.cxl-status.cx-active {
  display: block;
}
.cxl-status-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cxl-status-title h4 {
  font-size: 16px;
  font-weight: 600;
  color: #ee651e;
  margin: 0;
}
.cxl-load-status {
  margin-top: 15px;
  display: flex;
  position: relative;
}
.cxl-load-status::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 4px;
  background-color: #ecf2fa;
  z-index: 990;
}
.cxl-load-status li .cxl-status-circle {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 991;
  display: inline-block;
}
.cxl-load-status li.cx-status-ccenter {
  text-align: center;
}
.cxl-load-status li.cx-status-cright {
  text-align: right;
}
.cxl-load-status li {
  position: relative;
  flex-grow: 1;
}
.cxl-load-status li::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  bottom: 0;
  margin: auto;
  background: #ecf2fa;
  width: 100%;
  height: 4px;
  z-index: 991;
}
.cxl-load-status li img {
  display: none;
}
.cxl-load-status li.cxl-completed::before {
  background: #56b3f4;
}
.cxl-load-status li.cxl-completed img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.cxl-load-status li p {
  font-size: 12px;
  margin-bottom: 0;
}
.cxl-load-status li span {
  font-size: 12px;
}
.cxl-status-details {
  position: absolute;
}
.cxl-load-status li.cx-status-cleft .cxl-status-details {
  top: 25px;
  left: 0;
}
.cxl-load-status li.cx-status-ccenter .cxl-status-details {
  top: 25px;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  text-align: center;
}
.cxl-load-status li.cx-status-cright .cxl-status-details {
  top: 25px;
  right: 0;
}

/*------------------------------------
            Details Style
------------------------------------*/
.cxl-details {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  margin: auto;
}
.cxl-load-details {
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 0;
  border-radius: 15px;
  height: 100%;
}
.cxl-load-details.cxl-load-small {
  display: none;
}
.cxl-driver-details {
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 0;
  border-radius: 15px;
  height: 100%;
}
.cxl-ltitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}
.cxl-ltitle {
  border-bottom: 1px solid #e7e7e7;
}
.cxl-ltitle h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.cxl-lbtns button {
  display: inline-block;
  margin-left: 20px;
  letter-spacing: 0.7px;
}
.cxl-lbtns button.cxl-status-toggler {
  color: #ee651e;
}
.cxl-lpdetails {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  position: relative;
}
.cxl-lpdetails > img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 15px;
}
.cxl-lpdetails ul.cxl-load-ul li {
  padding-bottom: 3px;
}
.cxl-lpdetails ul.cxl-load-ul li h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.cxl-lpdetails ul.cxl-load-ul li label {
  font-size: 14px;
  margin-right: 12px;
}
.cxl-lpdetails ul.cxl-load-ul li > span {
  font-size: 14px;
  font-weight: 600;
  color: #1c0909;
}
.cxl-cost {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  font-weight: 700;
}
.cxl-lpdetails ul li button {
  margin-right: 10px;
}
.cxl-lpdetails ul li button img {
  height: 30px;
  width: 30px;
}
.cxl-cos {
  display: none;
}

/* Rating Styles */
.cxl-rating img {
  display: inline-block;
  margin-right: 10px;
}
.cxl-rating {
  margin-bottom: 12px;
}

/* Route Styles */
.cxl-routes {
  margin-top: 15px;
}
.cxl-routes div {
  display: inline-block;
  margin-right: 30px;
}
.cxl-from-route label,
.cxl-to-route label {
  display: block;
  font-size: 12px;
  color: #5f5f5f;
}
.cxl-from-route span,
.cxl-to-route span {
  display: block;
  font-size: 16px;
  color: #1c0909;
}
.cxl-route-info {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 40px;
}
.cxl-route-info span {
  font-size: 12px;
  color: #5f5f5f;
  display: block;
}
.cxl-route-info span:nth-last-child(1) {
  margin-bottom: 15px;
}
.cxl-route-info img.cxl-route-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
img.cxl-route-line-v {
  display: none;
}
.cxl-close-details {
  display: none;
}
.cxl-km {
  margin-bottom: 15px;
}
.cxl-min {
  margin-top: 15px;
}

@media (max-width: 991px) {
  .cxl-load-details.cxl-load-small {
    display: block;
    position: absolute;
    bottom: 75px;
    left: 30px;
    right: 30px;
    margin: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.03);
  }
  .cxl-details {
    display: none;
  }
  .cxl-load-small .cxl-lpdetails {
    padding-bottom: 0;
  }
  .cxl-status {
    position: fixed;
    top: 0;
    right: 0;
    left: unset;
    border-radius: 0;
    height: 100%;
    z-index: 1101;
  }
  .cxl-load-status {
    display: block;
  }
  .cxl-load-status li {
    display: flex;
    padding-bottom: 30px;
  }
  .cxl-status {
    width: 300px;
  }
  .cxl-status-circle {
    margin-right: 15px;
  }
  .cxl-status-details {
    position: static;
    display: inline-block;
  }
  .cxl-load-status li:nth-child(1)::before {
    height: 50%;
    top: 0;
  }
  .cxl-load-status li::before {
    height: 100%;
    width: 4px;
    top: -50%;
    left: 6px;
    bottom: 0;
    margin: auto;
  }
  .cxl-load-status li:nth-last-child(1)::before {
    height: 50%;
    top: -110%;
  }
  .cxl-load-status::before {
    content: none;
  }
  .cxl-load-status li.cx-status-ccenter {
    text-align: left;
  }
  .cxl-load-status li.cx-status-ccenter .cxl-status-details {
    text-align: left;
  }
  .cxl-load-status li.cx-status-cright .cxl-status-details {
    text-align: left;
  }
  .cxl-load-status li.cx-status-cright {
    text-align: left;
  }
  .cxl-load-status li.cx-status-cright .cxl-status-details {
    text-align: left;
  }

  .cxl-details {
    position: fixed;
    top: 0;
    right: 0;
    left: unset;
    bottom: unset;
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.03);
    width: 350px;
    z-index: 1101;
    height: 100%;
  }
  .cxl-details.cx-active {
    display: block;
  }
  .cxl-load-details {
    background: transparent;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
  .cxl-driver-details {
    background: transparent;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
  .cxl-close-details {
    display: block;
    color: #ee651e;
  }
  .cxl-close-details:hover {
    color: #ee651e;
  }
  .cxl-lbtns button.cxl-status-toggler {
    display: none;
  }
  .cxl-cos {
    display: block;
  }
  .cxl-cost {
    display: none;
  }
  .cxl-route-info img {
    position: absolute;
    top: 60px;
    left: -175px;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(90deg);
  }
  .cxl-km {
    margin-bottom: 5px;
  }
  .cxl-min {
    margin-top: 5px;
  }
  .cxl-to-route {
    margin-top: 60px;
  }
  img.cxl-route-line-v {
    display: block;
  }
  img.cxl-route-line {
    display: none;
  }
  .cxl-route-info {
    padding-top: 50px;
  }
}

@media (max-width: 576px) {
  .cxl-status {
    width: 100%;
  }
  .cxl-load-details.cxl-load-small {
    left: 0;
    right: 0;
    bottom: 48px;
    border-radius: 0;
  }
  .cxl-details {
    width: 100%;
  }
  .cxl-lbtns button {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .cxl-details {
    position: relative;
  }
}
