#cxpro-details {
    background-color: #E9F5FB;
    box-shadow: 15px 9px 25px 0 rgb(0 0 0 / 10%);
    height: 70px;
    align-items: Center;
    
    
}
.header-img-privacy{
width: 175px;
margin-left: 20px;

}

p{
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Overpass', sans-serif;
    margin-top: 30px;
    margin-left: 30px;
}
h3 {
    font-weight: 800;
    font-size: 1.75rem;
    margin-top: 30px;
    margin-left: 30px;
}
ul{
    line-height: 28px;
    list-style-type: disc !important;
    margin-top: 30px;
    margin-left: 50px;
}
li{
    
        display: list-item;
        text-align: -webkit-match-parent;
        margin-top: 12px;
    
}