/*------------------------------------
            Header Style
------------------------------------*/
.cxz-navbar{
    background:#ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.05);
}
.cxz-navbar-list li{
    padding:0 25px;
}
.cxz-navbar-list li:nth-last-child(1){
    padding-right:0;
}
.cxz-navbar-list li a{ 
    color:#5F5F5F;
    font-weight:600;
    cursor: pointer;
}
.cxz-navbar-list li a.active{
    color:#56B3F4;
}
.cxz-navbar-list li a:hover{
    color:#56B3F4;
}
.navbar-toggler:focus{
    box-shadow: 0 0 0 0 rgba(0,0,0,.0);
}
.navbar-toggler i{
    font-size:24px;
}


/*------------------------------------
            About Us Style
------------------------------------*/
#cxz-about{
    padding:100px 0;
    background:#ffffff;
    padding-top:135px;
}
.cxz-about-content h2{
    font-size:30px;
}
.cxz-about-content.cxz-img{
    display: flex;
    justify-content: center;
    width:100%;
    position: relative;
}
.cxz-about-content.cxz-img img{
    width:300px;
    height:300px;
    object-fit:cover;
    position:relative;
    z-index:1011;
}
.cxz-about-content.cxz-img::before{
    content:'';
    position:absolute;
    top:-35px;
    left:0;
    right:75px;
    margin:auto;
    width:300px;
    height:300px;
    background:#56B3F4;
    z-index:1010;
}
.cxz-about-content h4{
    font-size:30px;
}
@media(max-width:576px){
    #cxz-about{
        padding:30px 0;
    }
    .cxz-img{
        margin-top:50px;
    }
    .cxz-about-content h2{
        font-size:24px;
    }
}


/*------------------------------------
           Banner Style
------------------------------------*/
#cxz-hero{
    background:url('../images/landing/landing_banner.png');
    height:450px;
    padding-top:60px;
}
#cxz-hero .container{
    height:100%;
}
#cxz-hero .row{
    height:100%;
}
.cxz-banner-content{
    text-align: center;
}
.cxz-banner-content h2{
    font-size:30px;
    color:#ffffff;
}
.cxz-banner-content p{
    color:#ffffff;
    margin:0;
}
.cxz-banner-content .cx-btn-grp .cxz-banner-btn{
    background:#ffffff;
    border:0;
    color:#56B3F4;
    border-radius:50px;
    height:44px;
    line-height:44px;
    width:200px;
    margin-top:50px;
    border:1px solid #ffffff;
    transition:all .5s;
}
.cxz-banner-content .cx-btn-grp .cxz-banner-btn:hover{
    background:rgba(255,255,255,.15);
    border:1px solid #ffffff;
    color:#ffffff;
}
@media(max-width:576px){
    #cxz-hero{
        padding:30px 0;
        padding-top:90px;
    }
}



/*------------------------------------
           Download Style
------------------------------------*/
#cxz-download{
    background:#f7f7f7;
    padding:100px 0;
}
.cxz-download-content{
    text-align: center;
}
.cxz-download-content p{
    margin-top:30px;
    margin-bottom:0;
}
.cxz-download-content img{
    padding: 0 15px;
}
@media(max-width:576px){
    #cxz-download{
        padding:30px 0;
    }
    .cxz-download-content img{
        padding: 0px 5px;
        width: 160px;
    }
}


/*------------------------------------
           Contact Style
------------------------------------*/
#cxz-contact{
    padding:100px 0;
    background:#ffffff;
}
.cxz-contact-content h2{
    font-size:30px;
}
.cxz-contact-icontent{
    margin-top:85px;
}
.cxz-contact-icontent .form-control{
    background-color: transparent;
}
.cxz-contact-icontent ul{
    margin-bottom:30px;
}
.cxz-contact-icontent ul li{
    position:relative;
    padding-left:30px;
    display:inline-block;
    margin-right:50px;
}
.cxz-contact-icontent ul li img{
    position:absolute;
    top:0;
    left:0;
}
.cxz-contact-icontent > img{
    width:300px;
    height:300px;
    position:relative;
    z-index:1011;
}
.cxz-img-graphic{
    position:relative;
    z-index:1011;
}
.cxz-img-graphic::before{
    content:'';
    position:absolute;
    top:-35px;
    left:0;
    right:75px;
    margin:auto;
    width:300px;
    height:300px;
    background:#56B3F4;
    z-index:1010;
}
.cxz-contact-icontent button{
    border:0;
    border-radius:50px;
    padding:7px 50px;
}
.cxz-contact-icontent button:hover{
    background:#4997CE;
}
@media(max-width:576px){
    #cxz-contact{
        padding:30px 0;
    }
    .cxz-contact-icontent{
        margin-top:50px;
    }
    .cxz-contact-content h2{
        font-size:24px;
    }
}



/*------------------------------------
           Footer Style
------------------------------------*/
#cxz-footer{
    background:#FDE7DC;
    padding-top:50px;
}
.cxz-footer-content{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    text-align: center;
}
.cxz-footer-content img{
    margin-bottom:30px;
}
.cxz-footer-content p{
    margin:0;
}
.cxz-footer-content ul li{
    display:inline-block;
    padding:30px 15px;
}
.cxz-footer-content ul li a{
    color:#1C0909;
}
.cxz-footer-content ul li a:hover{
    color:#56B3F4;
}
.cxz-footer-copyright{
    display:flex;
    align-items: center;
    justify-content: center;
    background:#EE651E;
    color:#ffffff;
    height:44px;
}