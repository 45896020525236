/*------------------------------------
            Register Styles
------------------------------------*/
#cx-reg {
  background: url("../images/login/login_bg.svg");
  background-position: center;
  height: 100vh;
}
.cx-reg-out {
  width: 100%;
  height: 100%;
}
.cx-reg-out .row {
  height: 100%;
}
.cx-reg-lcontent {
  display: flex;
  justify-content: center;
  width: 100%;
}
.cx-pdefault {
  object-fit: cover;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

@media (max-width: 1199px) {
  #cx-reg {
    padding: 50px;
  }
}
@media (max-width: 991px) {
  #cx-reg {
    padding: 50px;
  }
}
@media (max-width: 767px) {
  .cx-reg-lcontent {
    margin-bottom: 30px;
  }
  #cx-reg {
    padding: 30px;
  }
}
@media (max-width: 540px) {
  .cx-reg-lcontent {
    margin-bottom: 30px;
  }
  #cx-reg {
    height: auto;
    padding: 30px;
  }
}

/*------------------------------------
        Content Styles
------------------------------------*/
.cx-reg-content {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 50px;
  width: 700px;
  max-height: 95vh;
  overflow-y: auto;
  
}
::-webkit-scrollbar {
    display: none !important;
   
  }
.cx-reg-content h2 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}
.cx-rp {
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}

.cx-reg-content .cx-btn-grp {
  margin-bottom: 30px;
}
.cx-eye-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}
.cx-reg-content .cx-reg-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #56b3f4;
  color: #ffffff;
  height: 44px;
  border: 0;
  border-radius: 10px;
  padding: 0 15px;
  letter-spacing: 0.7px;
}
.cx-reg-content .form-control {
  height: 44px;
  line-height: 44px;
  border: 1px solid #dddddd;
}
.cx-form-group {
  margin-bottom: 20px;
  position: relative;
}
.cx-reg {
  text-align: center;
}
.cx-reg p {
  margin-bottom: 0;
}
.cx-form-group label {
  font-size: 14px;
  text-align: left;
  margin-bottom: 7px;
  color: #5f5f5f;
}
.cx-reg-content .cx-form-group label sup {
  color: red;
}
.cx-profile-pic {
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.cx-profile-pic .cx-pdefault {
  display: inline-block;
}
.cx-profile-pic .cx-camera {
  position: absolute;
  top: 50px;
  left: 60px;
  right: 0;
  bottom: 0;
  margin: auto;
}
.cx-profile-pic .cx-form-group input {
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
}
.cx-profile-pic .cx-form-group label {
  cursor: pointer;
}
