/*------------------------------------
                Styles
------------------------------------*/
#cxpro-details{
    padding:30px 0;
}
.cxpro-bdetails-content{
    background-color: #ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
}
.cxpro-details-content{
    background-color: #ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
}
.cxpro-details{
    padding:30px;
}
.cxpro-details-banner{
    background:url('../images/Details/details_bg.png');
    background-size: cover;
    height:150px;
    border-radius:15px 15px 0 0;
    position:relative;
}
.cxpro-details-banner button{
    position:absolute;
    top:30px;
    right:30px;
}
.cxpro-load-image{
    margin-top:-90px;
    position: relative;
}
.cxpro-load-image img{
    width:120px;
    height:120px;
    position: relative;
    z-index: 802;
}
.cxpro-load-graphic{
    position: absolute;
    width:120px;
    height:120px;
    background-color:#AFDAF8;
    top:-4px;
    left:-4px;
    border-radius:50%;
    z-index: 801;
}
.cxpro-load-name{
    position:absolute;
    top:75px;
    left:140px;
}
.cxpro-load-name label{
    margin-right:12px;
}
.cxpro-details-list{
    padding-top:30px;
    display:flex;
    align-items: center;
}
.cxpro-details-list li{
    flex-grow:1;
}
.cxpro-details-list li label{
    font-size:12px;
}
.cxpro-details-list li p{
    color:#1C0909;
    font-weight:600;
    margin:0;
}
.cxpro-dcondition{
    padding:30px 0;
    border-bottom:1px solid #e7e7e7;
}
.cxpro-dcondition label{
    font-size:12px;
}
.cxpro-dcondition p{
    color:#1C0909;
    font-weight:600;
    margin-bottom:0;
}
.cxpro-images{
    padding-top:30px;
}
.cxpro-images img{
    display:inline-block;
    margin-right:30px;
    width:72px;
    height:72px;
    border-radius:10px;
}
.cxpro-images label{
    font-size:12px;
    margin-bottom:15px;
    display:block;
}

.cxpro-details-content{
    padding:30px;
    margin-top:30px;
}
.cxpro-content-title h4{
    color:#EE651E;
    font-size:16px;
}
.cxpro-content-details ul li h4{
    font-size:16px;
    margin:0;
}
.cxpro-content-details ul li a{
    font-size:14px;
    text-decoration: underline;
}
.cxpro-content-details ul li{
    padding-top:5px;
}


table, th, td {
    border: 1px solid;
  }




@media(max-width:991px){
    .cxpro-details-list{
        display:block;
    }
    .cxpro-details-list li{
        display:inline-block;
        margin-right:50px;
        margin-top:30px;
    }
    .cxpro-details-list{
        padding-top:0;
    }
}
@media(max-width:767px){
    .cxpro-load-name {
        position: static;
        margin-top: 15px;
    }
}