/*------------------------------------
            Login Styles
------------------------------------*/
#cx-login {
  background: url("../images/login/login_bg.svg");
  background-position: center;
  height: 100%;
}
.cx-login-out {
  width: 100%;
  height: 100%;
}
.cx-login-out .row {
  height: 100vh;
}
.cx-login-lcontent {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 767px) {
  .cx-login-lcontent {
    margin-bottom: 30px;
  }
}
@media (max-width: 540px) {
  .cx-login-lcontent {
    margin-bottom: 30px;
  }
  .cx-login-out {
    padding: 30px;
  }
}

/*------------------------------------
        Login Content Styles
------------------------------------*/
.cx-login-content {
  background: #ffffff;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 50px;
  width: 400px;
  text-align: center;
}
.cx-login-content h2 {
  font-size: 24px;
  font-weight: 400;
}
.cx-p {
  margin-bottom: 15px;
  font-size: 14px;
}

.cx-login-content .cx-btn-grp {
  margin-bottom: 20px;
}
.cx-eye-btn {
  position: absolute;
  top: 10px;
  right: 15px;
}
.cx-login-content .cx-login-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #56b3f4;
  color: #ffffff;
  height: 44px;
  border: 0;
  border-radius: 10px;
  padding: 0 15px;
  letter-spacing: 0.7px;
}
.cx-login-content .form-control {
  height: 44px;
  line-height: 44px;
  border: 1px solid #dddddd;
}
.cx-form-group {
  margin-bottom: 30px;
  position: relative;
}
.cx-reg p {
  margin-bottom: 0;
}
#login-left-logo-name {
  margin-top: 25px;
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
}
.radio-toolbar{
    margin-bottom: 10px;
}
.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  font: normal normal bold 14px/22px Overpass;
  letter-spacing: 0.34px;
  color: #56b3f4;
  display: inline-block;
  background: #04b5f012 0% 0% no-repeat padding-box;
  border: 1px solid #04b5f080;
  border-radius: 7px;
  padding: 4px 11px;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
}

.radio-toolbar input[type="radio"]:checked + label {
  background: #04b5f0 0% 0% no-repeat padding-box;
  border-radius: 7px;
  letter-spacing: 0.34px;
  color: #ffffff;
}
