.progress-marker::after {
  z-index: 0 !important;
}

.responsive-stepper {
  display: none;
}

.progress-step.is-complete .progress-marker::before,
.progress-step.is-progress .progress-marker::before {
  background: url("../images/status_tick.svg") !important;
}

.progress-step.is-complete .progress-marker::after,
.progress-step.is-progress .progress-marker::after {
  background-color: #ee651e !important;
}

.progress-step.is-active .progress-marker::before {
  background: url("../images/circle.svg") !important;
}
@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .responsive-stepper {
    display: block;
  }
  .desktop-stepper {
    display: none;
  }
}

.progress-text {
  p {
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
  }
}

.details-card {
  padding: 30px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0e9e9;
  border-radius: 15px;
  opacity: 1;
  .details-img {
    width: 350px;
    height: 200px;
    border-radius: 15px;
  }
}
