@import url(./responsive.scss);

.load-details-main {
  .load-imgs {
    display: flex;
    img {
      margin-right: 20px;
      height: 200px;
      width: 200px;
      border-radius: 10px;
    }
  }
  .pickup-location-left {
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    padding: 15px;
    width: calc(50% - 10px);
  }
  .pickup-location-right {
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    padding: 15px;
    width: calc(50% - 10px);
  }
  #pickup-location {
    background-color: transparent;
    .cxp-details-content {
      box-shadow: none;
      padding: unset;
      margin-top: unset;
    }
  }
  .pickup-location {
    .address {
      label {
        font-size: 14px;
      }
    }
    .name-heading {
      span {
        margin-right: 10px;
        font: normal normal normal 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #1c0909;
      }
    }
    justify-content: space-between;
  }
  .package-name {
    font: normal normal 600 16px/31px Overpass;
    letter-spacing: 0.32px;
    color: #1c0909;
  }
}
.form-header {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 15px 15px 0px 0px;
  height: 60px;
  box-shadow: 0px 0px 16px #0000000d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  .left {
    font: normal normal normal 16px/24px Overpass;
    letter-spacing: 0.32px;
    color: #1c0909;
  }
  .right {
    font: normal normal normal 16px/24px Overpass;
    letter-spacing: 0.32px;
    color: #1c0909;
  }
}
.red-star {
  font: normal normal normal 14px/22px Overpass;
  letter-spacing: 0.28px;
  color: #ff0000;
}

.form-inner-top {
  a {
    font: normal normal bold 14px/31px Overpass;
    letter-spacing: 0.28px;
    color: #56b3f4;
  }
  margin-bottom: 20px;
  h4 {
    margin-bottom: 12px !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  span {
    display: inline-block;
    max-width: 80%;
    font: normal normal normal 12px/17px Overpass;
    letter-spacing: 0.24px;
    color: #a49f9f;
  }
}
.dragable-card-main {
  margin-bottom: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0e9e9;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  .name {
    font: normal normal normal 16px/31px Overpass;
    letter-spacing: 0.32px;
    color: #1c0909;
  }
  p {
    margin-bottom: 0 !important;
    line-height: 25px !important;
  }
  .address {
    font: normal normal normal 14px/31px Overpass;
    letter-spacing: 0.28px;
    color: #5f5f5f;
  }
}
.location-add-card-main {
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 20px;
  height: 50vh;
  overflow: auto;

  #search-location-input {
    height: 44px;
    padding-left: 35px;
    background: #f7f7f7 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: none;
    &::placeholder {
      font: normal normal normal 14px/22px Overpass;
      letter-spacing: 0.28px;
      color: #5f5f5f;
    }
  }
  .search-wrapper-main {
    position: relative;
    svg {
      color: #5f5f5f;
      position: absolute;
      top: 14px;
      left: 12px;
    }
  }
  .choose-location-address {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    svg {
      color: #5f5f5f;
    }
    img {
      margin-bottom: 2px;
    }
    a {
      font: normal normal bold 14px/31px Overpass;
      letter-spacing: 0.28px;
      color: #56b3f4;
    }
  }
  .hr-line {
    height: 1px;
    background: #f0e9e9 0% 0% no-repeat padding-box;
    margin: 20px -20px;
  }
  .saved-location {
    h4 {
      font: normal normal bold 16px/31px Overpass;
      letter-spacing: 0.64px;
      color: #ee651e;
      opacity: 1;
      margin-bottom: 0;
    }
    .name {
      font: normal normal 600 16px/31px Overpass;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
    .address {
      font: normal normal normal 14px/20px Overpass;
      letter-spacing: 0.28px;
      color: #5f5f5f;
    }
    .hr-line-1 {
      height: 1px;
      background: #f0e9e9 0% 0% no-repeat padding-box;
      margin: 20px 0;
    }
  }
}
#cancel-location {
  font: normal normal bold 18px/24px Overpass;
  letter-spacing: 0.72px;
  color: #db4949;
}
.PickupLocationMap-main {
  .pickupLocation-head {
    z-index: 5456;
    position: absolute;
    top: 30px;
    width: calc(100% - 60px);
    padding: 20px 30px;
    left: 30px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0000001a;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    .left {
      font: normal normal normal 16px/24px Overpass;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
    .right {
      font: normal normal normal 16px/24px Overpass;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
  }
  .PickupLocationMap-bottom {
    .title {
      text-align: left;
      font: normal normal bold 14px/31px Overpass;
      letter-spacing: 0.56px;
      color: #a49f9f;
      margin-bottom: 15px;
    }
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #0000001a;
    border-radius: 15px;
    width: calc(100% - 60px);
    padding: 30px;
    position: absolute;
    left: 30px;
    bottom: 30px;
    .PickupLocationMap-bottom-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .address {
          font: normal normal 600 18px/18px Overpass;
          letter-spacing: 0.4px;
          color: #1c0909;
          margin-bottom: 10px;
        }
        .sub-add {
          font: normal normal normal 14px/20px Overpass;
          letter-spacing: 0.28px;
          color: #5f5f5f;
        }
      }
      .right {
        a {
          font: normal normal 600 16px/23px Overpass;
          letter-spacing: 0.32px;
          color: #ffffff;
          background: #56b3f4 0% 0% no-repeat padding-box;
          border-radius: 10px;
          padding: 12px 35px;
        }
      }
    }
  }
  .black-pickup-card {
    text-align: center;
    .top {
      font: normal normal bold 12px/20px Overpass;
      letter-spacing: 0.36px;
      color: #ffffff;
    }
    .bottom {
      font: normal normal bold 10px/31px Overpass;
      letter-spacing: 0.4px;
      color: #ffffff;
      opacity: 0.75;
    }
    position: absolute;
    top: 150px;
    left: 100px;
    background: #1c0909 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 10px;
  }
}
.SendLoadPackageDetails {
  .top-add-packages-main {
    display: flex;
    justify-content: space-between;
    img {
      margin-bottom: 2px;
    }
    a {
      font: normal normal bold 14px/31px Overpass;
      letter-spacing: 0.28px;
      color: #56b3f4;
    }
    .right {
      label {
        font: normal normal normal 12px/31px Overpass;
        letter-spacing: 0.24px;
        color: #5f5f5f;
      }
    }
  }
  .package-card {
    border: 1px solid #f0e9e9;
    border-radius: 15px;
    padding: 20px;
    width: calc(50% - 15px);
    .top {
      .left {
        font: normal normal 600 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #1c0909;
      }
    }
    .content {
      label {
        font: normal normal normal 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #5f5f5f;
        margin-right: 10px;
      }
      span {
        font: normal normal 600 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #1c0909;
      }
    }
    .img-package {
      margin-top: 10px;
      img {
        height: 72px;
        width: 72px;
        border-radius: 10px;
        margin-right: 15px;
      }
    }
  }
}
.SendLoadAddPackages {
  .form-section {
    border-bottom: 1px solid #f0e9e9;
    justify-content: space-between;
    .left {
      width: calc(50% - 15px);
      .drag-drop-main {
        label {
          font: normal normal normal 14px/24px Overpass;
          letter-spacing: 0.56px;
          color: #bcb7b7;
        }
        a {
          text-decoration: underline;
          font: normal normal normal 14px/24px Overpass;
          letter-spacing: 0.56px;
          color: #56b3f4;
        }
        text-align: center;
        padding: 50px;
        border: 1px dashed #dddddd;
        border-radius: 10px;
      }
      .vehicle_img {
        height: 72px;
        width: 72px;
        margin-right: 15px;
      }
    }
    .right {
      width: calc(50% - 15px);
    }
    .add-package-input {
      border: 1px solid #dddddd;
      border-radius: 5px;
    }
  }
  .select-location {
    margin-top: 20px;
    .heading {
      font: normal normal normal 14px/22px Overpass;
      letter-spacing: 0.28px;
      color: #5f5f5f;
    }
    .left {
      width: calc(50% - 15px);
    }
    .right {
      width: calc(50% - 15px);
    }
    .card-select-locaton {
      margin-top: 10px;
      padding: 20px;
      border: 1px solid #f0e9e9;
      border-radius: 15px;
      height: 150px;

      .card-select-locatio-inner {
        align-items: flex-start;
        justify-content: space-between;
        display: flex;
      }
      .seprat-line {
        border-bottom: 1px solid #f0e9e9;
        margin: 10px 0;
      }
      .left-section {
        display: flex;
        .left-inner {
          margin-right: 10px;
        }
        .right-inner {
          margin-top: -5px;
          .name {
            font: normal normal normal 16px/31px Overpass;
            letter-spacing: 0.32px;
            color: #1c0909;
          }
          .address {
            font: normal normal normal 14px/20px Overpass;
            letter-spacing: 0.28px;
            color: #5f5f5f;
          }
        }
      }
      .right-section {
      }
    }
  }
}
.SendLoadBookingSummary {
  .booking-status-main {
    border-bottom: 1px solid #f0e9e9;
    padding-bottom: 20px;
    margin-bottom: 20px;
    transition: all 0.5s;
    .stepper-content {
      transition: all 0.5s;
      .expanded-card {
        transition: all 0.5s;
        margin: 10px 0px;
        border: 1px solid #f0e9e9;
        border-radius: 15px;
        padding: 20px;
        .top {
          display: flex;
          justify-content: space-between;
          .left {
            font: normal normal 600 16px/31px Overpass;
            letter-spacing: 0.32px;
            color: #1c0909;
          }
          .right {
            font: normal normal 600 16px/31px Overpass;
            letter-spacing: 0.32px;
            color: #56b3f4;
          }
        }
        .package-pics {
          margin-top: 10px;
          img {
            height: 72px;
            width: 72px;
            border-radius: 10px;
            margin-right: 10px;
          }
        }
        .values {
          font: normal normal 600 14px/31px Overpass;
          letter-spacing: 0.28px;
          color: #1c0909;
        }
        .key {
          margin-right: 10px;
          font: normal normal normal 14px/31px Overpass;
          letter-spacing: 0.28px;
          color: #5f5f5f;
        }
      }
      .name {
        font: normal normal normal 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #1c0909;
      }
      .address {
        line-height: 15px;
        font: normal normal normal 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #5f5f5f;
      }
      .package-expand-main {
        display: flex;
        justify-content: space-between;
        .left {
          font: normal normal bold 14px/31px Overpass;
          letter-spacing: 0.56px;
          color: #ee651e;
        }
        .right {
          margin-right: -150px;
          .expand {
            font: normal normal bold 16px/23px Overpass;
            letter-spacing: 0.32px;
            color: #56b3f4;
          }
        }
      }
    }
    .left-main {
      border-right: 1px solid #f0e9e9;
      width: calc(66.66% - 15px);
      margin-right: 15px;
    }
    .right-main {
      .key-name {
        font: normal normal normal 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #5f5f5f;
      }
      .key-value {
        font: normal normal 600 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #1c0909;
      }
      .key-value-orange {
        font: normal normal 600 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #ee651e;
      }
    }
  }
  .offers-benifit-main {
    .top {
      display: flex;
      justify-content: space-between;
      .right {
        a {
          font: normal normal bold 16px/23px Overpass;
          letter-spacing: 0.32px;
          color: #56b3f4;
        }
      }
    }
    .title {
      font: normal normal normal 16px/23px Overpass;
      letter-spacing: 0.32px;
      color: #707070;
    }
    .offer-cards-main {
      margin-top: 20px;
      justify-content: space-between;
      .offer-card {
        position: relative;
        padding: 30px 20px 20px 20px;
        width: calc(50% - 15px);
        border: 1px solid #f0e9e9;
        border-radius: 15px;
        .top-heading {
          display: flex;
          justify-content: space-between;
          .left {
            font: normal normal 600 20px/31px Overpass;
            letter-spacing: 0.4px;
            color: #1c0909;
          }
          .right {
            a {
              font: normal normal bold 16px/31px Overpass;
              letter-spacing: 0.32px;
              color: #56b3f4;
            }
          }
        }
        .save {
          font: normal normal normal 12px/31px Overpass;
          letter-spacing: 0.24px;
          color: #ee651e;
        }
        p {
          font: normal normal normal 12px/17px Overpass;
          letter-spacing: 0.24px;
          color: #5f5f5f;
        }
        #ribben-top {
          position: absolute;
          top: 0;
          left: 5px;
          img {
          }
          .ribben-text {
            z-index: 879;
            position: relative;
            top: -8px;
            left: 70px;
            font: normal normal bold 16px/31px Overpass;
            letter-spacing: 0.32px;
            color: #1c0909;
          }
        }
      }
    }
  }
  #stepper {
    .line {
      width: 2px;
      background-color: #ee651e !important;
    }
  }
  #stepper {
    .line-1 {
      width: 2px;
      background-color: #ac9d95 !important;
    }
  }
}
.SendLoadPaymentMethod {
  h4 {
    margin-bottom: unset !important;
    font: normal normal bold 14px/31px Overpass;
    letter-spacing: 0.56px;
    color: #ee651e;
  }
  .credit-card-main {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    .left {
      .card-number {
        font: normal normal normal 16px/31px Overpass;
        letter-spacing: 0.32px;
        color: #1c0909;
      }
      .name {
        font: normal normal normal 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #5f5f5f;
        line-height: 15px;
      }
      .expiry {
        font: normal normal normal 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #5f5f5f;
      }
    }
    .right {
      .form-check-input:checked[type="radio"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-size: 18px;
      }
      .form-check-input:checked {
        background-color: #56b3f4;
        border: none;
        box-shadow: none;
      }
      .form-check-input[type="radio"] {
        border: 2px solid #f4eeee;
        height: 30px;
        width: 30px;
      }
    }
  }
}
#OverAllStatus {
  max-width: 500px;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 15px;
  .booking-status-main .left-main {
    width: 100%;
    border: none;
    margin-right: unset;
  }
  .booking-status-main {
    border: none;
  }
  .load-status-name {
    font: normal normal 600 16px/31px Overpass;
    letter-spacing: 0.32px;
    color: #1c0909;
  }
  .load-status-main {
    border-bottom: 1px solid #f0e9e9;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-right: -150px;
  }
  .stepper-content .address {
    line-height: 20px;
  }
  .name-call {
    display: flex;
    justify-content: space-between;
    .call {
      margin-right: -150px;
    }
  }
}
.ServiceRequestedLoadsEditModifyLoad {
  .edit-pickup-location {
    margin-bottom: 20px;
    .top {
      display: flex;
      justify-content: space-between;
      .left-edit {
        font: normal normal normal 14px/22px Overpass;
        letter-spacing: 0.28px;
        color: #5f5f5f;
        .delete-selected-loc {
          margin-top: 10px;
          font: normal normal normal 16px/31px Overpass;
          letter-spacing: 0.32px;
          color: #1c0909;
          position: relative;
          background: #f9f4f4 0% 0% no-repeat padding-box;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          padding: 2px 10px;
        }
        .number {
          margin-left: 10px;
          font: normal normal normal 16px/31px Overpass;
          letter-spacing: 0.32px;
          color: #ee651e;
        }
        a {
          margin-left: 10px;
          img {
            height: 11px;
            width: 11px;
          }
        }
      }
      .right-edit {
        a {
          font: normal normal bold 14px/31px Overpass;
          letter-spacing: 0.28px;
          color: #56b3f4;
        }
      }
    }
  }
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ee651e;
}

.route-page-name {
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #1c0909;
}
.expand-new-css{
}
.expand-images-wrapper{
  flex-wrap: wrap;
}
