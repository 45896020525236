.home-card-1 {
  background: #eff7fd 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0000000d;
  border-radius: 15px;
  opacity: 1;
  padding: 17px 20px;
  height: auto;
  margin: 40px 30px 0px 0px;
  .banner-btn {
    background: #ee651e 0% 0% no-repeat padding-box;
    border-radius: 22px;
    width: 246px;
    height: 44px;
    opacity: 1;
    border: none;
    color: #ffffff;
    padding: 11px 20px;
    margin-top: 20px;
  }
  .banner-img {
    margin-top: -40px;
  }
  .btn-typo {
    font-family: Overpass;
    font-size: 0.9rem;
    letter-spacing: 0.38px;
    color: #ffffff;
    opacity: 1;
    text-align: center;
  }
  .banner-arrow {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  h1 {
    font-family: Overpass;
    font-weight: normal;
    font-size: 2.25rem;
    letter-spacing: 0.86px;
    color: #2c3244;
    opacity: 1;
  }
}

.section-2 {
  margin-top: 25px;
  h5 {
    font: normal normal normal 24px/24px Overpass;
    letter-spacing: 0.72px;
    color: #1c0909;
  }
  p {
    font-weight: 600;
    font-family: Overpass;
    font-size: 0.8rem;
    letter-spacing: 0.42px;
    color: #56b3f4;
    opacity: 1;
  }
}
.service-card {
  background: #eff7fd 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0000000d;
  border-radius: 15px;
  height: 191px;
  padding: 15px 20px;
  opacity: 1;
  h6 {
    font-family: Overpass;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.64px;
    color: #1c0909;
    opacity: 1;
  }
  p {
    margin-bottom: 6px;
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #5f5f5f;
    opacity: 1;
  }
  span {
    font-family: Overpass;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.28px;
    color: #1c0909;
    opacity: 1;
  }
}


.home-modal {
  .modal-body {
    .btn-yes-no {
      text-align: center;
      button {
        height: 30px;
        width: 60px;
      }
      .yes {
        font: normal normal 600 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #ff3131;
        background: transparent;
        border: 1px solid #ff2f35;
        border-radius: 10px;
      }
      .no {
        margin-right: 20px;
        font: normal normal 600 14px/31px Overpass;
        letter-spacing: 0.28px;
        color: #ffffff;
        background: #dbdada 0% 0% no-repeat padding-box;
        border-radius: 10px;
        border: none;
      }
    }
    p {
      text-align: center;
      font: normal normal medium 16px/24px Overpass;
      letter-spacing: 0.32px;
      color: #1c0909;
    }
    padding: 0;
  }
  .modal-content {
    padding: 30px;
    max-width: 367px;
  }
  .modal-dialog-centered {
    justify-content: center;
  }
}
