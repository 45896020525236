/*------------------------------------
               Common Styles
------------------------------------*/
.cxs-loc-content{
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0, 0, 0, .03);
    width:800px;
    padding:30px;
    /* border-radius:15px 15px 0 0; */
    overflow-y: auto;
    overflow-x: hidden;
}
.cxs-content-outer{
    height:100%;
}
.cxs-nav{
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0, 0, 0, .03);
    border-radius:0 0 15px 15px;
    width:800px;
    margin-top:5px;
    height:48px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 30px;
}
.cxs-nav button img{
    margin-right:10px;
}
.cxs-nav button img:nth-last-child(1){
    margin-right:0;
    margin-left:10px;
}
.cxs-nav button span{
    position:relative;
    top:1px;
}
@media(max-width:768px){
    .cxs-nav{
        width:100%;
    }
    .cxs-loc-content{
        width:100%;
    }
}
@media(max-width:576px){
    .cxs-loc-content{
        padding:15px;
    }
    #cxs-dload {
        height: auto;
        padding: 15px 15px 45px 15px;
    }
}


/*------------------------------------
            Step 1 Styles
------------------------------------*/
#cxs-locations{
    height:100%;
}
#cxs-locations .container-fluid{
    height:100%;
}
#cxs-locations .row{
    height:100%;
}
.cxs-from{
    margin-bottom:50px;
}
.cxs-from h4{
    font-size:16px;
    color:#EE651E;
    margin-bottom:20px;
}
.cx-form-group label{
    color:#5F5F5F;
    font-size:14px;
}

.cxs-to h4{
    font-size:16px;
    color:#EE651E;
    margin-bottom:20px;
}
.cxs-upload-thumbnail{
    position: relative;
    text-align: center;
}
.cxs-upload-thumbnail .cxs-pdefault{
    display: inline-block;
}
.cxs-upload-thumbnail .cxs-camera{
    position: absolute;
    top:80px;
    left:70px;
    right:0;
    bottom:0;
    margin:auto;
}
.cxs-upload-thumbnail .cx-form-group input{
    height:0;
    width:0;
    position:absolute;
    opacity:0;
}
.cxs-upload-thumbnail .cx-form-group label{
    cursor:pointer;
}

.cxs-upload-image .cx-form-group input{
    height:0;
    width:0;
    position:absolute;
    opacity:0;
}
.cxs-upload-image .cx-form-group label{
    cursor:pointer;
}

@media(max-width:768px){
    #cxs-locations{
        height:auto;
        padding:15px 0 45px 0;
    }
    #cxs-locations .row{
        height:auto;
    }
    #cxs-locations .container-fluid{
        padding:0 15px;
    }
}


/*------------------------------------
            Step 2 Styles
------------------------------------*/
#cxs-dload{
    height:100%;
}
#cxs-dload .container-fluid{
    height:100%;
}
#cxs-dload .row{
    height:100%;
}
.cxs-details .cx-form-group{
    margin-bottom:30px;
}
.cxs-details .cx-form-group:nth-last-child(1){
    margin-bottom:0;
}
.cxs-upload label{
    font-size:14px;
    margin-bottom:7px;
}
.cxs-thumbnail{
    margin-top:30px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom:20px;
}
.cxs-thumbnail ul li{
    display:inline-block;
    margin-right:15px;
    position: relative;
}
.cxs-thumbnail ul li img.cxs-remove{
    position:absolute;
    top:0;
    right:-3px;
    cursor: pointer;
}
.cxs-thumbnail ul li img.cxs-ithumbnail{
    height:72px;
    width:72px;
    border-radius:10px;
    object-fit: cover;
}
.cxs-upload-thumbnail{
    margin:30px 0;
    position:relative;
    text-align: center;
}
.cxs-upload-thumbnail img{
    display: inline-block;
}
.cxs-upload-thumbnail .cxs-camera{
    position:absolute;
    top: 80px;
    left: 70px;
    right: 0;
    bottom: 0;
    margin: auto;
}
.cxs-iupload-form{
    width:100%;
    border:1px dashed #DDDDDD;
    border-radius:10px;
    height:250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cxs-iupload-form span{
    padding:5px 0; 
}
.cxs-iupload-form img{
    margin-bottom:15px;
}
.cxs-iupload-form .cxs-inner{
    color:#BCB7B7;
}
.cxs-iupload-form .cxs-select{
    color:#56B3F4;
    text-decoration: underline;
    cursor: pointer;
}
.cxs-iupload-form input{
    display:none;
}
.cxs-pdefault{
    object-fit: cover;
    height:100px;
    width:100px;
    border-radius:50%;
}

@media(max-width:768px){
    #cxs-dload{
        height:auto;
        padding:15px 0 45px 0;
    }
    #cxs-dload .row{
        height:auto;
    }
    #cxs-dload .container-fluid{
        padding:0 15px;
    }
    .cxs-upload{
        margin-bottom:30px;
    }
}




/*------------------------------------
            Step 3 Styles
------------------------------------*/
#cxs-summary{
    height:100%;
}
#cxs-summary .container-fluid{
    height:100%;
}
#cxs-summary .row{
    height:100%;
}
.cxs-summary-content{

}
.cxs-summary-content .col-md-8{
    padding-right:5px;
}
.cxs-summary-content .col-md-4{
    padding-left:0;
}
.cxs-summary-content-01{
    padding:30px;
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0, 0, 0, .03);
    border-radius:15px 0 0 0;
}
.cxs-summary-content-02{
    padding:30px;
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0, 0, 0, .03);
    border-radius:0 15px 0 0;
    height:100%;
}

.cxs-scontent{
    padding:30px 0;
    border-bottom:3px solid #f7f7f7;
}
.cxs-scontent:nth-child(1){
    padding-top:0;
}
.cxs-scontent:nth-last-child(1){
    padding-bottom:0;
    border-bottom:0;
}

/* Title Styles */
.cxs-scontent-title{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:10px;
}
.cxs-scontent-title h4{
    font-size:14px;
    color:#EE651E;
    margin:0;
}


/* Load Styles */
.cxs-scontent-details{
    display:flex;
    flex-direction:row;
}
.cxs-scontent-details img{
    margin-right:15px;
    width:48px;
    height:48px;
    border-radius:50%;
}
.cxs-scontent-details ul li{
    padding-top:5px;
}
.cxs-scontent-details ul li span{
    font-size:14px;
    font-weight: 600;
}
.cxs-scontent-details ul li label{
    font-size:14px;
    margin-right:12px;
}
.cxs-scontent-details ul li h4{
    font-size:16px;
    margin:0;
    font-weight:400;
}

/* Estimate Styles */
.cxs-srcontent{
    border-bottom:3px solid #f7f7f7;
}
.cxs-srcontent h4{
    font-size:14px;
    color:#EE651E;
    margin:0;
    margin-bottom:15px;
}
.cxs-srcontent ul li label{
    color:#5F5F5F;
    font-size:14px;
}
.cxs-srcontent ul li p{
    color:#1C0909;
    font-weight:600;
}
.cxs-srcontent:nth-last-child(1){
    margin-top:30px;
}

@media(max-width:768px){
    #cxs-summary{
        height:auto;
        padding:15px 0 45px 0;
    }
    #cxs-summary .row{
        height:auto;
    }
    #cxs-summary .container-fluid{
        padding:0 15px;
    }
    .cxs-upload{
        margin-bottom:30px;
    }
    .cxs-summary-content .col-md-8{
        padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
    .cxs-summary-content .col-md-4{
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
    .cxs-summary-content-01{
        margin-bottom:15px;
    }
}




/*------------------------------------
            Step 4 Styles
------------------------------------*/
#cxs-payment{
    height:100%;
}
#cxs-payment .container-fluid{
    height:100%;
}
#cxs-payment .row{
    height:100%;
}
.cxs-pcontent-title{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:30px;
}
.cxs-pcontent-title h4{
    color:#EE651E;
    font-size:16px;
    margin:0;
}
.cxs-pcontent-title button img{
    margin-right:10px;
    margin-top:-5px;
}
.cxs-pcontent-title button{
    font-size:14px;
}




/* Custom Radio Styles */
.cxs-card-list{
    height:500px;
}
.cxs-card-list li .form-check{
    position:relative;
}
.cxs-card-list li .form-check input{
    height:0;
    width:0;
    position:absolute;
    opacity:0;
}
.cxs-card-list li .form-check label{
    display:flex;
    justify-content: space-between;
    cursor: pointer;
}
.cxs-card-list li .form-check label .cxs-radio{
    border:2px solid #F4EEEE;
    height:24px;
    width:24px;
    border-radius:50%;
}
.cxs-card-list li .form-check label .cxs-radio img{
    display:none;
}
.cxs-card-list li .form-check input:checked ~ label .cxs-radio{
    background:#56B3F4;
    border:0 solid #F4EEEE;
}
.cxs-card-list li .form-check input:checked ~ label .cxs-radio img{
    display:block;
}
.cxs-radio{
    display:flex;
    justify-content: center;
    align-items: center;
}
.cxs-card-details h4{
    font-size:16px;
    margin-bottom:5px;
    font-weight:400;
}
.cxs-card-details p{
    margin-bottom:5px;
    font-size:14px;
}
.cxs-card-list .form-check{
    padding-left:0;
}
.cxs-card-list li{
    padding:30px 0;
    border-bottom:1px solid #e7e7e7;
}
.cxs-card-list li:nth-last-child(1){
    padding-bottom:0;
    border-bottom:0;
}
.cxs-card-list li:nth-child(1){
    padding-top:0;
}


@media(max-width:768px){
    #cxs-payment{
        height:auto;
        padding:15px 0 45px 0;
    }
    #cxs-payment .row{
        height:auto;
    }
    #cxs-payment .container-fluid{
        padding:0 15px;
    }
}