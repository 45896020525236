/*------------------------------------
              Map Styles
------------------------------------*/
#cx-map-container{
    position: relative;
    transition:all .3s;
}
#cx-map{
    height:calc(100vh - 60px);
}
.gm-style .gm-style-iw-c{
    box-shadow:0 4px 16px 0 rgba(0,0,0,.1);
    border-radius:15px;
}
.gm-style-iw-t{
    bottom:90px !important;
}
.gm-style .gm-style-iw-t::after{
    background: linear-gradient(45deg,rgba(86,179,244,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
}
.gm-ui-hover-effect{
    position:relative;
}
.gm-ui-hover-effect img{
    position:absolute;
    top:10px;
    right:10px;
}

@media(max-width:991px){
    #cx-map-container{
        margin-left:0;
    }
}



/*------------------------------------
            Location Selector
------------------------------------*/
.cx-slocation{
    width:350px;
    border-radius:15px;
    position:absolute;
    top:30px;
    left:30px;
    /* height:calc(100vh - 120px); */
    height: auto;
    /* background:#ffffff; */
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.1);
    /* padding:5px; */
    z-index:1001;
}
.cx-slocation button{
    position: relative;
    background: none;
    border: none;
    padding: 10px;

}
.cx-slocation-content{
    border-radius:15px;
    background-color: #56B3F4;
    padding:15px;
    padding-bottom:0;
}
.form-control{
    background: #F7F7F727 0% 0% no-repeat padding-box;
border-radius: 10px;
opacity: 1;
border: 1px solid #DDDDDD;}
.form-control::placeholder{
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.8rem ;
letter-spacing: 0.28px;
color: #FFFFFF;
opacity: 1;
}
.cx-slocaton-title{
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:20px;
}
.cx-slocaton-title h4{
    color:#ffffff;
    font-weight:700;
    font-size:18px;
    margin-bottom:0;
}
.cx-slocaton-title button{
    padding:0;
    background:transparent;
    border:0;
}
.cx-slocation-content img{
 cursor: pointer;
}

.location-card{
    padding: 20px 20px;
    background:#ffffff;
    width:350px;
    border-radius:15px;

}
.location-card h6{
    font-weight: bold;
    font-family: Overpass;
    font-size: 0.9rem ;
    letter-spacing: 0.64px;
    color: #EE651E;
    opacity: 1;
}

.location-card p{
    font-family: Overpass;
    font-weight: normal;
    font-size: 0.9rem ;
letter-spacing: 0.48px;
color: #1C0909;
opacity: 1;
}
.location-add{
    /* width: 310px; */
height: 44px;
margin-top: 37px;
background: #56B3F4 0% 0% no-repeat padding-box;
border: none;
border-radius: 10px;
opacity: 1;
text-align: center;
font-family: Overpass;
font-weight: bold;
font-size:0.9rem ;
letter-spacing: 0.32px;
color: #FFFFFF;
}
.location-card label{
    font-weight: normal;
    font-family: Overpass;
    font-size: 0.75rem ;
letter-spacing: 0.36px;
color: #5F5F5F;
opacity: 1;
}

.cx-slocation-content .form-control:focus{
box-shadow: none;
}

.currentLocation span{

    font-family: Overpass;
    font-weight: bold;
    font-size: 0.8rem ;
letter-spacing: 0.28px;
color: #FFFFFF;
opacity: 1;
}
.currentLocation p{

    font-family: Overpass;
    font-size: 0.8rem ;
letter-spacing: 0.28px;
margin-left: 22px;
color: #FFFFFF;
opacity: 1;
}
.currentLocation img{
    cursor: pointer;
}
.cx-form-group1{
    position:relative;
    padding-left:30px;
}
.cx-form-group1 .cx-pickup, .cx-form-group .cx-drop{
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    margin:auto;
}
.cx-form-group1 .cx-route{
    position:absolute;
    top:93px;
    left:3px;
    bottom:0;
    margin:auto;
}
.cx-form-group1 .form-control{
    background:rgba(255,255,255,.15);
    
}
.cx-form-group1 .form-control:focus{
    box-shadow: 0 0 0 0.25rem rgb(255,255,255,.25);
}
.cx-form-group1 .form-label{
    color: #5F5F5F;
    border:0;
    font-size:12px;
}
.cx-slocatoin-loc{
    padding:15px;
}
.cx-slocatoin-loc ul li{
    padding:20px 0;
    border-bottom:1px solid #eeeeee;
    padding-left:30px;
    position:relative;
}
.cx-slocatoin-loc ul li img{
    position:absolute;
    top:20px;
    left:0;
}
.cx-slocatoin-loc ul li h5{
    font-size:16px;
    margin-bottom:5px;
    font-weight:400;
}
.cx-slocatoin-loc ul li p{
    font-size:12px;
    margin:0;
}
.cx-saved-loc-toggler{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.cx-saved-loc-toggler button{
    background:transparent;
    box-shadow: 0 0 0 0 rgba(0,0,0,.15);
    border:0;
    width:auto;
    letter-spacing: 1px;
    height:56px;
    color:#ffffff;
    margin:0;
}
.cx-saved-loc-toggler button img{
    padding-left:15px;
}
.cx-sendload-toggler{
    position:absolute;
    top:30px;
    left:380px;
    display:none;
}
.cx-sendload-toggler2{
    position:absolute;
    top:30px;
    left:380px;
}
.cx-sendload-toggler button{
    background:#ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.15);
    border-radius:50px;
    border:0;
    width:auto;
    padding-left:20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    height:48px;
}
.cx-sendload-toggler2 button{
    background:#ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.15);
    border-radius:50px;
    border:0;
    width:auto;
    padding-left:20px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 1px;
    height:48px;
}
.cx-sclose-btn{
    display:none;
}
.cx-sendload-toggler button img{
    padding-left:20px;
}

@media(max-width:768px){
    .cx-sendload-toggler2{
        top: 100px !important;
        left: 30px !important;
        }
}
@media(max-width:991px){
    .cx-sendload-toggler{
        display:block;
        left:30px;
    }
    .cx-slocation{
        width:300px;
        border-radius:0;
        position:fixed;
        top:0;
        right:-300px;
        left:unset;
        height:100%;
        background:#ffffff;
        box-shadow: 0 4px 16px 0 rgba(0,0,0,.1);
        padding:5px;
        z-index:1010;
        transition: all .5s;
    }
    .cx-slocation.cx-open{
        right:0;
    }
    .cx-sclose-btn{
        display:inline-block;
        margin-left:10px;
    }
}
@media(max-width:576px){
    .cx-saved-loc-toggler{
        left:15px;
        top:80px;
    }
    .cx-sendload-toggler{
        left:15px;
        top:15px
    }
    .cx-sendload-toggler button{
        font-size:14px;
    }
    .cx-saved-loc-toggler button{
        font-size:14px;
    }
    
}

.offcanvas-btn{
    position: absolute;
    bottom: 0;
    padding: 10px 20px;
    width: 280px;
    height: 44px;
    background: #56B3F4 0% 0% no-repeat padding-box;
    border: 1px solid #56B3F4;
    border-radius: 10px;
    opacity: 1;
}


.offcanvas-btn button{
    color: #ffffff;
    background: none;
    border: none;
}









/*------------------------------------
            Sloc Styles
------------------------------------*/
#cx-saved-loc{
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index:1020;
    display:none;
}
#cx-saved-loc.cx-open{
    display:block;
}
.cx-sloc-out{
    position:relative;
    width:100%;
    height:100%;
}
.cx-filter{
    position:absolute;
    top:0;
    left:0;
    background:rgba(0,0,0,.25);
    width:100%;
    height:100%;
    cursor:pointer;
}
.cx-sloc-content{
    position: absolute;
    top:0;
    right:0;
    background:#ffffff;
    width:320px;
    height:100%;
    transition:all .5s ease;
}
#cx-sloc.cx-open .cx-sloc-content{
    right:0;
}
.cx-sloc-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:60px;
    padding:0 20px;
    border-bottom:1px solid #eeeeee;
}
.cx-sloc-header button{
    background:transparent;
    border:0;
    padding:0;
}
.cx-sloc-header h4{
    font-size:16px;
    margin:0;
}
.cx-sloc-content ul{
    padding:20px;
    padding-top:0;
}
.cx-sloc-content ul li{
    padding:20px 0;
    border-bottom:1px solid #eeeeee;
}
.cx-sloc-content ul li a img{
    margin-right:12px;
    position:relative;
    top:-2px;
}
.cx-con-btn{
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    background:#56B3F4;
    color:#ffffff;
    height:44px;
    border:0;
    border-radius:10px;
    padding:0 15px;
    letter-spacing: .7px;
}
.cx-sloc-content .cx-btn-grp{
    padding:0 15px;
}
.cx-sloc-list li .form-check{
    padding-left:0;
    margin-bottom:0;
}
.cx-sloc-list{
    height: calc(100vh - 150px);
    margin-bottom: 30px;
    overflow-y: auto;
}



.cx-sloc-list li .form-check{
    position:relative;
}
.cx-sloc-list li .form-check input{
    height:0;
    width:0;
    position:absolute;
    opacity:0;
}
.cx-sloc-list li .form-check label{
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.cx-sloc-list li .form-check label .cx-radio{
    border:2px solid #F4EEEE;
    height:24px;
    width:24px;
    border-radius:50%;
}
.cx-sloc-list li .form-check label .cx-radio img{
    display:none;
}
.cx-sloc-list li .form-check input:checked ~ label .cx-radio{
    background:#56B3F4;
    border:0 solid #F4EEEE;
}
.cx-sloc-list li .form-check input:checked ~ label .cx-radio img{
    display:block;
}
.cx-radio{
    display:flex;
    justify-content: center;
    align-items: center;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before{
    background:#56B3F4;
    right: 5px;
}