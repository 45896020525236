/*------------------------------------
            General Styles
------------------------------------*/
html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  font-family: "Overpass", sans-serif;
  background-color: #f7f7f7;
  letter-spacing: 0.7px;
  padding: 0;
  margin: 0;
  color: #1c0909;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  letter-spacing: 1.5px;
  color: #1c0909;
}
p {
  color: #5f5f5f;
}
label {
  color: #5f5f5f;
}
a {
  text-decoration: none;
  color: #1c0909;
}
a {
  color: #56b3f4;
}
a:hover {
  color: #4997ce;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

/*------------------------------------
            Button Styles
------------------------------------*/
.cx-text-btn {
  background: transparent;
  border: 0;
  padding: 0;
  color: #56b3f4;
  letter-spacing: 0.7px;
  -webkit-appearance: none;
}
.cx-text-btn:hover {
  color: #4997ce;
}
.cx-btn {
  background: #56b3f4;
  color: #ffffff;
  letter-spacing: 0.7px;
}

/*------------------------------------
            Input Styles
------------------------------------*/
.form-control {
  border: 1px solid #dddddd;
  border-radius: 7px;
}
.form-select {
  border: 1px solid #dddddd;
  border-radius: 7px;
}
.cx-form-group {
  margin-bottom: 30px;
}

/*------------------------------------
            Main Styles
------------------------------------*/
main.cx-main {
  height: calc(100vh - 60px);
  margin-left: 70px;
  overflow-x: hidden;
  transition: all 0.3s;
  padding: 20px 20px 0px 20px;
}
main.cx-main.cx-active {
  margin-left: 250px;
}

@media (max-width: 991px) {
  main.cx-main {
    margin-left: 0;
  }
}

/*------------------------------------
            Simplebar Styles
------------------------------------*/
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: #56b3f4;
  right: 5px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  background: #56b3f4;
  right: 5px;
}

.pac-container {
  z-index: 1002 !important;
}
.uploaded_items .item_list .load_img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.uploaded_items .item_list .close_item {
  top: -4px !important;
  left: 40px !important;
}
.cxd-details .cxd-load-image img {
  border-radius: 50%;
  margin: -5px;
}
.cxd-doc-content .cxd-doc {
  height: 190px;
  border-radius: 10px;
}
.required:after {
  content: "*";
  color: red;
}
.for-error {
  color: red !important;
  display: none;
}
.header-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.cxr-profile .cxr-profile-img img {
  border-radius: 50%;
  margin: -5px;
}
.itemside .aside .header-profile-pic {
  width: 70px;
  height: 70px;
}
.cxs-upload-image{
  height: 42% !important;
  width: 290px !important; 
}

