.offer-card{
    padding: 30px 13px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #F0E9E9;
border-radius: 15px;
opacity: 1;
position: relative;
img{
    position: absolute;
    left:14px;
    top: 0px;
    transform: translateY(-9px);
}
.offer-heading{
    font-weight: 600;
    font-family: Overpass;
    font-size:  1.25rem ;
letter-spacing: 0.4px;
color: #1C0909;
opacity: 1;
}
.offer-apply{
font-weight: bold;
font-family: Overpass;
    font-size:  0.9rem ;
letter-spacing: 0.32px;
color: #56B3F4;
opacity: 1;
}
.save-amount{
font-weight: normal;
font-family:  Overpass;
    font-size: 0.75rem;
letter-spacing: 0.24px;
color: #EE651E;
opacity: 1;
}

}