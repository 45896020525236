/*------------------------------------
        Notification Styles
------------------------------------*/
#cxpm-notifications{
    padding:30px 0;
}
.cxpm-ncontent{
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.03);
    padding:30px;
    border-radius:15px;
    display:flex;
    position:relative;
}
.cxpm-ncontent p{
    margin-bottom:5px;
    color:#1C0909;
}
.cxpm-ncontent img.cxpm-not-image{
    margin-right:20px;
    width:32px;
    height:32px;
}
.cxpm-ncontent span{
    color:#5F5F5F;
    font-size:14px;
}
.cxpm-ncontent .cx-btn-grp1{
    position:absolute;
    top:30px;
    right:30px;
}
.cxpm-ncontent button{
    position:relative;
}
.cxpm-dropdown{
    position:absolute;
    top:0;
    right:25px;
    background:#ffffff;
    box-shadow:0 4px 16px 0 rgba(0,0,0,.1);
    padding:15px;
    width:150px;
    border-radius:5px;
    display:none;
}
.cxpm-dropdown li{
    text-align: left;
}
.cxpm-dropdown.cx-active{
    display:block;
}
.cxpm-ncontent ul li h4{
    font-size:16px;
    margin:0;
    font-weight:500;
}
.cxpm-ncontent{
    margin-bottom:30px;
}
@media(max-width:576px){
    #cxpm-notifications{
        padding:15px 0;
    }
}

