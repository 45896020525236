/*=====================================
---------------------------------------
R E S P O N S I V E  - CSS  - S T A R T 
---------------------------------------
=======================================*/

/*=====================================
---------------------------------------
BRAKE POINTS:-
            - max-width: 1024px
            - min-width: 768px
            - max-width: 667px            
            - max-width: 600px            
            - max-width: 568px
            - max-width: 480px
            - max-width: 375px Main Changer
            - max-width: 320px            
---------------------------------------   
=======================================*/

/*@media screen and (min-width: 1366px) {
.container {
    width: 1300px;
    max-width: 100%;
}

} this is a setting for larger screens */

@media all and (max-width: 1166px) {
}

/*================================================================================
    @media all and (max-width: 1024px) START Tab
  ================================================================================*/

@media all and (max-width: 991px) {
  .SendLoadBookingSummary .booking-status-main .left-main {
    border-right: none !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 990px) {
  .cx-main-for-responsive {
    overflow: hidden !important;
  }
  .loads-content-for-responsive {
    height: 100vh !important;
  }
  .accept-btn {
    margin-left: 3px !important;
    margin-top: 15px;
  }
}
/*================================================================================
    @media all and (max-width: 1024px) END Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 768px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 768px) {
  .SendLoadBookingSummary .offers-benifit-main .offer-cards-main .offer-card {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .SendLoadBookingSummary
    .booking-status-main
    .stepper-content
    .package-expand-main
    .right {
    margin-right: unset !important;
  }
  .SendLoadAddPackages .form-section .left {
    width: 100% !important;
  }
  .SendLoadAddPackages .form-section .right {
    width: 100% !important;
  }
  .SendLoadPackageDetails .package-card {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .SendLoadAddPackages .select-location .left {
    width: 100% !important;
  }
  .SendLoadAddPackages .select-location .right {
    width: 100% !important;
    margin-top: 15px;
  }
  .cx-main-for-responsive {
    overflow: hidden !important;
  }
}

/*================================================================================
    @media all and (max-width: 768px) END iPhone - 6
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 667px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 667px) {
}

/*================================================================================
    @media all and (max-width: 667px) END iPhone - 6
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 600px) START Tab
  ================================================================================*/

@media all and (max-width: 600px) {
}

/*================================================================================
    @media all and (max-width: 600px) END Tab
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 568px) START iPhone - 5
  ================================================================================*/

@media all and (max-width: 568px) {
  .SendLoadBookingSummary .booking-status-main .stepper-content .expanded-card {
    margin-right: unset !important;
  }
}

/*================================================================================
    @media all and (max-width: 568px) END iPhone - 5
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 480px) START iPhone - 3 + 4
  ================================================================================*/
@media all and (max-width: 480px) {
  .SendLoadPaymentMethod
    .credit-card-main
    .right
    .form-check-input[type="radio"] {
    margin-left: 10px;
  }
  .form-inner-top a {
    font-size: 12px !important;
  }
  .SendLoadBookingSummary
    .booking-status-main
    .stepper-content
    .expanded-card
    .package-pics
    img {
    margin-bottom: 15px;
  }
  .SendLoadAddPackages .select-location .card-select-locaton .left-section {
    margin-bottom: 10px;
  }
  .SendLoadAddPackages
    .select-location
    .card-select-locaton
    .card-select-locatio-inner {
    display: block !important;
  }
  .PickupLocationMap-main
    .PickupLocationMap-bottom
    .PickupLocationMap-bottom-inner {
    .right {
      a {
        width: 100% !important;
        display: block;
        text-align: center;
      }
    }
    .left {
      margin-bottom: 15px;
    }
    display: block !important;
  }
  .PickupLocationMap-main .pickupLocation-head {
    padding: 15px !important;
    width: calc(100% - 30px) !important;
    left: 15px !important;
    top: 15px !important;
  }
  .PickupLocationMap-main .PickupLocationMap-bottom {
    width: calc(100% - 30px) !important;
    bottom: 15px !important;
    left: 15px !important;
    padding: 15px !important;
  }
  main.cx-main {
    padding: 0 !important;
  }
  .cxs-from h4 {
    font-size: 14px !important;
  }
  .SendLoadAddPackages .form-section .left .drag-drop-main {
    padding: 20px !important;
  }
  .home-card-1 h1 {
    font-size: 20px !important;
  }
  #cx-tab {
    justify-content: space-between;
    font-size: 14px;
    margin-left: 44px;
    margin-right: 30px;
  }
  .service-card-load {
    margin-left: 30px;
  }
  .cx-main-for-responsive {
    overflow: hidden !important;
  }

  .service-card-load-for-company {
    margin-left: 30px;
  }
}
/*================================================================================
    @media all and (max-width: 480px) END iPhone - 3 + 4
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 375px) START iPhone - 6
  ================================================================================*/

@media all and (max-width: 375px) {
}

/*================================================================================
    @media all and (max-width: 375px) END iPhone - 6
  ================================================================================*/

/*================================================================================
    @media all and (max-width: 320px) START iPhone - 3 + 4
  ================================================================================*/

@media all and (max-width: 320px) {
}

/*================================================================================
    @media all and (max-width: 320px) END iPhone - 3 + 4
  ================================================================================*/

/*=====================================
---------------------------------------
R E S P O N S I V E  - CSS  -     E N D 
---------------------------------------
=======================================*/
