.cxt-content{
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.03); 
    background:#ffffff;
    padding:30px;
    border-radius:15px;
}
.cxt-content p{
    color:#1C0909;
}
#cxt-terms{
    padding:30px 0;
}