/*------------------------------------
            Rating Syles
------------------------------------*/
#cxap-rating{
    padding:30px;
    height:100%;
}
#cxap-rating .container{
    height:100%;
}
#cxap-rating .row{
    height:100%;
}
.cxap-contact-outer{
    width:500px;
    background:#ffffff;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,.03);
    border-radius:15px;
    padding:50px;
}
.cxap-profile{
    text-align: center;
}
.cxap-profile-img{
    position: relative;
    display:inline-block;
    margin-bottom:30px;
}
.cxap-profile h5{
    margin-bottom:3px;
    font-size:16px;
}

.cxap-profile img{
    width:120px;
    height:120px;
    position: relative;
    z-index: 802;
}
.cxap-profile .cxap-load-graphic{
    position: absolute;
    width:120px;
    height:120px;
    background-color:#AFDAF8;
    top:-4px;
    left:-4px;
    border-radius:50%;
    z-index: 801;
}
.cxap-rate{
    padding:30px 0 50px 0;
}
.cxap-rate ul{
    width:100%;
    text-align: center;
}
.cxap-rate ul li{
    display:inline-block;
    padding:0 15px;
    cursor: pointer;
}
.cxap-rate ul li.cx-active svg g path{
    fill:#F08A29;
}
.cxap-rate p{
    text-align: center;
    margin-top:15px;
    margin-bottom:0;
}
.cxap-rate-btn{
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
    background:#56B3F4;
    color:#ffffff;
    height:44px;
    border:0;
    border-radius:10px;
    padding:0 15px;
    letter-spacing: .7px;
}
.cxap-contact-outer form .form-control{
    border-radius:10px;
}
.cxap-contact-outer form label{
    font-size:14px;
}
.cxap-contact-outer form .form-control::placeholder{
    letter-spacing: 1px;
}

@media(max-width:576px){
    #cxap-rating{
        padding:15px 0;
    }
    #cxap-rating .row{
        height:auto;
    }
    .cxap-contact-outer{
        padding: 30px 15px;
    }
}